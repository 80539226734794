import React, { PropsWithChildren } from "react";
import StyledPayStatusIconWrapper from "@/components/ui/PayStatusIconWrapper/PayStatusIconWrapper.styled.ts";

type Props = {
  status: "SUCCESS" | "FAIL";
} & PropsWithChildren;

const PayStatusIconWrapper: React.FunctionComponent<Props> = ({
  status,
  children
}) => {
  return (
    <StyledPayStatusIconWrapper status={status}>
      {children}
    </StyledPayStatusIconWrapper>
  );
};

export default PayStatusIconWrapper;
