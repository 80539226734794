import styled from "styled-components";

const StyledContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: clamp(296px, 90vw, 360px);
  box-sizing: border-box;
  position: relative;
`;

export default StyledContainer;
