import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import useKhaltiCheckout from "@/hooks/useKhaltiCheckout.ts";

/**
 * Khalti Buffer View receives search params as given Below:
 * pidx=Bo8khJRJwHiWmpiCn8AJCJ
 * &transaction_id=2tRr95c4YXMchUhMb3hpLA
 * &tidx=2tRr95c4YXMchUhMb3hpLA
 * &amount=10000
 * &total_amount=10000
 * &mobile=98XXXXX001
 * &status= Status
 * &purchase_order_id=reservation/reference ID
 * &purchase_order_name=reservation/reference ID
 *
 * Intended to checkout Khalti Payment Gateway
 */
const KhaltiBufferView: React.FunctionComponent = () => {
  const [searchParams] = useSearchParams();

  const pidx = searchParams.get("pidx") as string;
  const amount = searchParams.get("amount") as string;
  const mobile = searchParams.get("mobile") as string;
  const purchaseOrderId = searchParams.get("purchase_order_id") as string;
  const purchaseOrderName = searchParams.get("purchase_order_name") as string;
  const transactionId = searchParams.get("transaction_id") as string;

  const checkout = useKhaltiCheckout({
    cb: () => {},
    cbSuccess: (data) => {
      if (data) {
        window.location.replace(`/payment/checkout/success/?ref=${data.id}`);
      }
    },
    cbError: () => {
      window.location.replace(`/payment/checkout/failure`);
    }
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const executeCheckout = () => {
    const condition = localStorage.getItem("buffer");

    if (!condition) {
      checkout.mutate({
        businessId: localStorage.getItem("businessId") as string,
        payload: {
          amount: parseInt(amount) / 100,
          reference_id: purchaseOrderId,
          normal_user_id: localStorage.getItem("normalUser") as string,
          feature: "seat-reservation",
          payment_provider_data: {
            pidx: pidx
          }
        }
      });
      localStorage.setItem("buffer", "true");
    }
  };

  useEffect(() => {
    if (
      pidx &&
      amount &&
      mobile &&
      purchaseOrderId &&
      purchaseOrderName &&
      transactionId
    ) {
      console.log("from the other side");
      executeCheckout();
    }
  }, [
    pidx,
    amount,
    mobile,
    purchaseOrderId,
    purchaseOrderName,
    transactionId,
    executeCheckout
  ]);

  return <></>;
};

export default KhaltiBufferView;
