import styled from "styled-components";

const StyledPaymentMethodsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const StyledPaymentMethodPortalCard = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  height: 40px;
  padding: 16px 0 16px 16px;
  border-radius: 5px;
  border: 0.8px solid #d9d9d9;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);

  &:focus-within {
    border: 2px solid ${({ theme }) => theme.colors.primary};
  }
`;

const StyledPaymentRadioInput = styled.input`
  accent-color: ${({ theme }) => theme.colors.primary};
`;

const StyledPaymentInputLabel = styled.label`
  width: 100%;
  padding-left: 22px;
`;

const StyledPaymentLabelImage = styled.img``;

export {
  StyledPaymentMethodsWrapper,
  StyledPaymentMethodPortalCard,
  StyledPaymentRadioInput,
  StyledPaymentInputLabel,
  StyledPaymentLabelImage
};
