import React, { useEffect, useState } from "react";
import Container from "@/components/ui/Container";
import Card from "@/components/ui/Card";
import {
  StyledFailureCardContent,
  StyledFailureDescription,
  StyledFailureHead,
  StyledTryAgainButton
} from "@/views/PaymentView/FailureView/FailureView.styled.ts";
import ForMoreInquiry from "@/components/ui/ForMoreInquiry";
import DownloadAppButton from "@/components/ui/DownloadAppButton";
import PayStatusIconWrapper from "@/components/ui/PayStatusIconWrapper";
import { useNavigate } from "react-router-dom";

const FailureView: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const [showTryAgain, setShowTryAgain] = useState<boolean>(false);

  const qrCode: string = localStorage.getItem("qrCode") || "";

  useEffect(() => {
    if (qrCode !== "") {
      setShowTryAgain(true);
    }
  }, [qrCode, setShowTryAgain]);

  const handleTryAgain = () => {
    navigate(`/qr/${qrCode}`, {
      replace: true
    });
  };

  return (
    <Container>
      <Card accent>
        <StyledFailureCardContent>
          <PayStatusIconWrapper status={"FAIL"}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
            >
              <path
                d="M14.0003 0.666687C6.62699 0.666687 0.666992 6.62669 0.666992 14C0.666992 21.3734 6.62699 27.3334 14.0003 27.3334C21.3737 27.3334 27.3337 21.3734 27.3337 14C27.3337 6.62669 21.3737 0.666687 14.0003 0.666687ZM19.7337 19.7334C19.6103 19.857 19.4638 19.955 19.3025 20.0219C19.1412 20.0888 18.9683 20.1233 18.7937 20.1233C18.619 20.1233 18.4461 20.0888 18.2848 20.0219C18.1235 19.955 17.977 19.857 17.8537 19.7334L14.0003 15.88L10.147 19.7334C9.89769 19.9827 9.55956 20.1227 9.20699 20.1227C8.85442 20.1227 8.51629 19.9827 8.26699 19.7334C8.01769 19.484 7.87763 19.1459 7.87763 18.7934C7.87763 18.6188 7.91202 18.4459 7.97882 18.2846C8.04563 18.1233 8.14355 17.9768 8.26699 17.8534L12.1203 14L8.26699 10.1467C8.01769 9.89738 7.87763 9.55925 7.87763 9.20669C7.87763 8.85412 8.01769 8.51599 8.26699 8.26669C8.51629 8.01738 8.85442 7.87733 9.20699 7.87733C9.55956 7.87733 9.89769 8.01738 10.147 8.26669L14.0003 12.12L17.8537 8.26669C17.9771 8.14324 18.1236 8.04532 18.2849 7.97852C18.4462 7.91171 18.6191 7.87733 18.7937 7.87733C18.9682 7.87733 19.1411 7.91171 19.3024 7.97852C19.4637 8.04532 19.6102 8.14324 19.7337 8.26669C19.8571 8.39013 19.955 8.53668 20.0218 8.69796C20.0886 8.85925 20.123 9.03211 20.123 9.20669C20.123 9.38126 20.0886 9.55412 20.0218 9.71541C19.955 9.8767 19.8571 10.0232 19.7337 10.1467L15.8803 14L19.7337 17.8534C20.2403 18.36 20.2403 19.2134 19.7337 19.7334Z"
                fill="#F43434"
              />
            </svg>
          </PayStatusIconWrapper>
          <StyledFailureHead>Your Purchase Failed!</StyledFailureHead>
          <StyledFailureDescription>
            Sorry! We could not process your payment.
          </StyledFailureDescription>
        </StyledFailureCardContent>
      </Card>
      <DownloadAppButton />
      <ForMoreInquiry />

      {showTryAgain && (
        <StyledTryAgainButton onClick={handleTryAgain}>
          Try Again
        </StyledTryAgainButton>
      )}
    </Container>
  );
};

export default FailureView;
