import React, { useEffect, useState } from "react";
import SuccessContainer from "@/views/PaymentView/SuccessView/SuccessView.styled.ts";
import DownloadAppButton from "@/components/ui/DownloadAppButton";
import ForMoreInquiry from "@/components/ui/ForMoreInquiry";
import PaySuccessTicketCard from "@/components/ui/PaySuccessTicketCard";
import { StyledTryAgainButton } from "@/views/PaymentView/FailureView/FailureView.styled.ts";
import { useNavigate, useSearchParams } from "react-router-dom";
import useReservationSummary from "@/hooks/useReservationSummary.ts";

const SuccessView: React.FunctionComponent = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [showBookAgain, setShowBookAgain] = useState<boolean>(false);

  const qrCode: string = localStorage.getItem("qrCode") || "";

  const { data, isLoading } = useReservationSummary({
    reservationId: searchParams.get("ref") || ""
  });

  useEffect(() => {
    if (qrCode !== "") {
      setShowBookAgain(true);
    }
  }, [qrCode, setShowBookAgain]);

  const handleBookAgain = () => {
    navigate(`/qr/${qrCode}`, {
      replace: true
    });
  };

  if (isLoading && !!data) {
    return <div>Loading...</div>;
  }

  return (
    <SuccessContainer>
      {data && <PaySuccessTicketCard data={data} />}
      <DownloadAppButton />
      <ForMoreInquiry />
      {showBookAgain && (
        <StyledTryAgainButton onClick={handleBookAgain}>
          Book Again
        </StyledTryAgainButton>
      )}
    </SuccessContainer>
  );
};

export default SuccessView;
