import styled from "styled-components";

const SuccessContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 330px;
  box-sizing: border-box;
  position: relative;
`;

export default SuccessContainer;
