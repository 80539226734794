import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from "react";
import { StyledEPayV2 } from "@/components/ui/EPayV2/EPayV2.styled.ts";
import EPayInputField from "@/components/ui/EPayV2/EpayInputFIeld.tsx";
import hmacSHA256 from "crypto-js/hmac-sha256";
import Base64 from "crypto-js/enc-base64";
import useBulkTableCartStore from "@/stores/BulkTableCartStore.ts";
import EsewaProviderConfig from "@/apiModels/PaymentModels/EsewaProviderConfig.ts";

export interface EPayV2Ref {
  submitForm: () => void;
}

type EPayV2Props = {
  reservationId: string;
  config: EsewaProviderConfig;
};

const EPayV2: React.ForwardRefExoticComponent<
  EPayV2Props & React.RefAttributes<EPayV2Ref>
> = forwardRef<EPayV2Ref, EPayV2Props>(
  (
    {
      reservationId,
      config: { VITE_ESEWA_HOST, VITE_ESEWA_PRODUCT_CODE, VITE_SECRET_KEY }
    },
    ref
  ) => {
    const [signatureB64, setSignatureB64] = useState<string>("");

    const { totalAmount } = useBulkTableCartStore((store) => ({
      totalAmount: store.totalAmount
    }));

    const generateSignature = () => {
      if (signatureB64) {
        return;
      }
      const hashDigest = hmacSHA256(
        `total_amount=${totalAmount},transaction_uuid=${reservationId},product_code=${VITE_ESEWA_PRODUCT_CODE}`,
        VITE_SECRET_KEY
      );
      setSignatureB64(Base64.stringify(hashDigest));
    };

    useEffect(() => {
      if (reservationId && totalAmount && signatureB64) {
        const form = document.getElementById("submit");
        if (form) {
          form.click();
        }
      }
    }, [signatureB64, reservationId, totalAmount]);

    useImperativeHandle(ref, () => ({
      submitForm: () => {
        console.log("[+] Generating Signature");
        generateSignature();
      }
    }));

    return (
      <StyledEPayV2
        action={`${VITE_ESEWA_HOST}/api/epay/main/v2/form`}
        method="POST"
      >
        <EPayInputField id={"amount"} name={"amount"} value={totalAmount} />
        <EPayInputField id={"tax_amount"} name={"tax_amount"} value={0} />
        <EPayInputField
          id={"total_amount"}
          name={"total_amount"}
          value={totalAmount}
        />
        <EPayInputField
          id={"transaction_uuid"}
          name={"transaction_uuid"}
          value={reservationId}
        />
        <EPayInputField
          id={"product_code"}
          name={"product_code"}
          value={VITE_ESEWA_PRODUCT_CODE}
        />

        <EPayInputField
          id={"product_service_charge"}
          name={"product_service_charge"}
          value={0}
        />

        <EPayInputField
          id={"product_delivery_charge"}
          name={"product_delivery_charge"}
          value={0}
        />

        <EPayInputField
          id={"success_url"}
          name={"success_url"}
          value={`${import.meta.env.VITE_HOST_URL}/payment/esewa`}
        />

        <EPayInputField
          id={"failure_url"}
          name={"failure_url"}
          value={`${import.meta.env.VITE_HOST_URL}/payment/checkout/failure`}
        />
        <EPayInputField
          id={"signed_field_names"}
          name={"signed_field_names"}
          value={"total_amount,transaction_uuid,product_code"}
        />
        <EPayInputField
          id={"signature"}
          name={"signature"}
          value={signatureB64}
        />
        <input id={"submit"} type="submit" hidden />
      </StyledEPayV2>
    );
  }
);

export default EPayV2;
