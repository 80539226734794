import styled from "styled-components";

const StyledReservationDateSelectorCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;

  & > svg {
    opacity: 0.2;
    cursor: not-allowed;
  }
`;

const StyledReservationDateSelectorCardContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin: 10px auto;

  svg {
  }

  span {
    color: ${({ theme }) => theme.colors.primary};
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

const StyledReservationDateSelector = styled.div`
  margin: 20px 0;

  h2 {
    color: ${({ theme }) => theme.colors.primary};
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 12px;
    text-align: center;
  }

  ${StyledReservationDateSelectorCardContent} {
  }
`;

export {
  StyledReservationDateSelectorCardWrapper,
  StyledReservationDateSelectorCardContent,
  StyledReservationDateSelector
};
