import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
    :root {
        font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
        line-height: 1.5;
        font-weight: 400;

        --unavailable-seat: ${({ theme }) => theme.colors.booked};
        --selected-seat: ${({ theme }) => theme.colors.hold};

        font-synthesis: none;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    * {
        box-sizing: border-box;
        padding: 0;
        margin: 0;
    }

    html {
        overflow: hidden;
    }

    html,
    body {
        min-width: 320px;
        max-width: 480px;
        height: 100%;
        margin: auto;
    }

    img.PhoneInputCountryIconImg {
        aspect-ratio: 1/1;
        width: unset;
    }

    .react-transform-component > svg {
        inset: 0;
        margin: auto;
    }
`;

export default GlobalStyles;
