export type UpdateSeatColorProp = {
  seat: string;
  color: string;
};

const updateSeatColor = ({ seat, color }: UpdateSeatColorProp) => {
  document.getElementById(seat)!.style.fill = color;
};

export default updateSeatColor;
