import APIClient from "@/services/APIClient";
import BaseServices from "@/services/BaseServices";
import { useQuery } from "@tanstack/react-query";

interface Props {
  reservationId: string;
}

const useReservationSummary = ({ reservationId }: Props) => {
  const apiClient = new APIClient<PaymentCheckoutResponse>(
    BaseServices.SEAT_RESERVATION_SERVICE,
    `/reservation/${reservationId}/retrieve-reservation-summary`
  );

  return useQuery<PaymentCheckoutResponse, Error>({
    queryKey: ["reservation-summary", reservationId],
    queryFn: () => apiClient.get(),
    enabled: !!reservationId
  });
};

export default useReservationSummary;
