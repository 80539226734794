import React from "react";

const AppleStoreIcon: React.FunctionComponent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="29"
      viewBox="0 0 24 29"
      fill="none"
    >
      <path
        d="M20.0455 15.2952C20.012 11.6616 23.0969 9.89388 23.238 9.81158C21.4909 7.32797 18.7828 6.98863 17.8312 6.96157C15.5568 6.7282 13.3506 8.28849 12.192 8.28849C11.0103 8.28849 9.22612 6.98412 7.30322 7.02245C4.82876 7.05965 2.51388 8.45647 1.24428 10.6256C-1.37587 15.0483 0.578254 21.5476 3.08855 25.1225C4.34428 26.8733 5.81161 28.8282 7.7322 28.7595C9.61117 28.6839 10.313 27.5915 12.5805 27.5915C14.8272 27.5915 15.4863 28.7595 17.445 28.7155C19.4616 28.6839 20.7312 26.9568 21.943 25.1902C23.3941 23.1834 23.9769 21.2071 24 21.1057C23.9526 21.0899 20.0837 19.6502 20.0455 15.2952Z"
        fill="#865CD0"
      />
      <path
        d="M16.3454 4.60985C17.356 3.37763 18.0474 1.70122 17.8555 0C16.3928 0.0631333 14.5635 0.986457 13.5102 2.19163C12.5782 3.25362 11.7457 4.99429 11.9607 6.63125C13.6038 6.75075 15.2908 5.82292 16.3454 4.60985Z"
        fill="#865CD0"
      />
    </svg>
  );
};

export default AppleStoreIcon;
