import React from "react";
import StyledLine from "@/components/ui/Line/Line.styled.ts";

type Props = {
  variant: "NORMAL" | "DASHED";
  stroke: `${number}px` | `${number}rem` | `${number}em`;
};

const Line: React.FunctionComponent<Props> = ({ stroke, variant }) => {
  return <StyledLine variant={variant} stroke={stroke} />;
};

export default Line;
