import React, { useState } from "react";
import {
  StyledAreaSelector,
  StyledAreaSelectorOption,
  StyledAreaSelectorOptionInput,
  StyledAreaSelectorOptionInputLabel,
  StyledAreaSelectorOptions,
  StyledAreaSelectorOptionWrapper,
  StyledAreaSelectorText,
  StyledAreaSelectorWrapper
} from "@/components/ui/AreaSelector/AreaSelector.styled.ts";
import CaretDownIcon from "@/components/icons/CaretDownIcon";
import { VenueArea } from "@/apiModels/ReservationScheduleAPIModel";
import { useLocation } from "react-router-dom";

type Props = {
  selectedArea: VenueArea;
  areas: VenueArea[];
  setSelectedArea: React.Dispatch<React.SetStateAction<VenueArea>>;
};

const AreaSelector: React.FunctionComponent<Props> = ({
  selectedArea,
  areas,
  setSelectedArea
}) => {
  const [isOptionOpen, setIsOptionOpen] = useState<boolean>(false);

  const location = useLocation();

  const handleSelectorClick = () => {
    setIsOptionOpen((state) => !state);
  };

  const handleAreaSelect = (area: VenueArea) => {
    setSelectedArea(() => {
      setIsOptionOpen(false);
      localStorage.setItem("switch", location.pathname.split("/")[2]);

      return area;
    });
  };

  return (
    <StyledAreaSelectorWrapper $isOpen={isOptionOpen}>
      <StyledAreaSelector onClick={handleSelectorClick}>
        <StyledAreaSelectorText>{selectedArea.name}</StyledAreaSelectorText>
        <CaretDownIcon />
      </StyledAreaSelector>
      <StyledAreaSelectorOptionWrapper>
        <StyledAreaSelectorOptions>
          {areas.map((option, index) => (
            <StyledAreaSelectorOption key={index} htmlFor={`area-${option.id}`}>
              <StyledAreaSelectorOptionInput
                type="checkbox"
                id={`area-${option.id}`}
                name="area"
                value={option.id}
                checked={selectedArea.id === option.id}
                onChange={() => handleAreaSelect(option)}
              />
              <StyledAreaSelectorOptionInputLabel>
                {option.name}
              </StyledAreaSelectorOptionInputLabel>
            </StyledAreaSelectorOption>
          ))}
        </StyledAreaSelectorOptions>
      </StyledAreaSelectorOptionWrapper>
    </StyledAreaSelectorWrapper>
  );
};

export default AreaSelector;
