import React from "react";
import { StyledNavbar } from "@/components/ui/NavBar/NavBar.styled.ts";
import RevelLogo from "@/assets/logo/REVEl.png";

const NavBar: React.FunctionComponent = () => {
  return (
    <StyledNavbar>
      <img src={RevelLogo} alt="REVEL" />
    </StyledNavbar>
  );
};

export default NavBar;
