import React from "react";
import { Outlet } from "react-router-dom";
import { StyledPublicLayout } from "@/components/layouts/PublicLayout/PublicLayout.styled.ts";

const PublicLayout: React.FunctionComponent = () => {
  localStorage.removeItem("buffer");
  localStorage.removeItem("successObject");
  return (
    <StyledPublicLayout>
      <Outlet />
    </StyledPublicLayout>
  );
};

export default PublicLayout;
