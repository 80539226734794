interface IBaseUrls {
  SEAT_RESERVATION_SERVICE: string;
  PAYMENT_SERVICE: string;
}

const BaseServices: IBaseUrls = {
  SEAT_RESERVATION_SERVICE: import.meta.env.VITE_SEAT_BASE_URL || "",
  PAYMENT_SERVICE: import.meta.env.VITE_PAYMENT_BASE_URL || ""
};

export default BaseServices;
export type { IBaseUrls };
