import React from "react";
import { StyledIconButton } from "@/components/ui/IconButton/IconButton.styled.ts";

type Props = {
  onClick: () => void;
  icon: React.ReactNode;
};

const IconButton: React.FunctionComponent<Props> = ({ icon, onClick }) => {
  return <StyledIconButton onClick={onClick}>{icon}</StyledIconButton>;
};

export default IconButton;
