import { useMutation } from "@tanstack/react-query";
import APIClient from "@/services/APIClient";
import BaseServices from "@/services/BaseServices";

type Props = {
  businessId: string;
} & IMutationHookProps<KhaltiInitResponse>;

const useKhaltiInit = ({ cb, cbError, cbSuccess, businessId }: Props) => {
  const apiClient = new APIClient<InitiatePaymentPayload>(
    BaseServices.PAYMENT_SERVICE,
    `/business/${businessId}/payment/khalti-initiate`
  );
  return useMutation<
    KhaltiInitResponse,
    CustomError<RevelErrorResponse>,
    InitiatePaymentPayload
  >({
    mutationFn: (payload) => apiClient.post<KhaltiInitResponse>(payload),
    onMutate: cb,
    onError: cbError,
    onSuccess: cbSuccess
  });
};

export default useKhaltiInit;
