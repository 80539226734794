import React from "react";
import StyledDownloadAppButton from "@/components/ui/DownloadAppButton/DownloadAppButton.styled.ts";
import PlayStoreIcon from "@/components/ui/Icon/PlayStoreIcon";
import AppleStoreIcon from "@/components/ui/Icon/AppStoreIcon";

const DownloadAppButton: React.FunctionComponent = () => {
  const handleDownloadApp = () => {
    window.open("https://revelarena.com/download", "_blank");
  };

  return (
    <StyledDownloadAppButton onClick={handleDownloadApp}>
      <PlayStoreIcon />
      <AppleStoreIcon />
      Download App
    </StyledDownloadAppButton>
  );
};

export default DownloadAppButton;
