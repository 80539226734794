import styled from "styled-components";

const StyledReservationSummaryContainer = styled.div`
  margin-top: 35px;
`;

const StyledTicketSummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const StyledTicketSummaryEventTitle = styled.h1`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.fonts.fontSizeMblHeadingOne};
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const StyledHorizontalLine = styled.hr`
  color: ${({ theme }) => theme.colors.primary};
`;

const StyledTicketSummaryDate = styled.h3`
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.fonts.fontSizeLg};
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const StyledReservationSummaryItemWrapper = styled.div`
  padding: 10px 0;
`;

const StyledReservationSummaryItem = styled.ul`
  width: 100%;
  margin-left: 0.625rem;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const StyledTicketSummaryPhasePriceListItem = styled.li`
  color: ${({ theme }) => theme.colors.lightGreyColor};
  font-size: ${({ theme }) => theme.fonts.fontSizeMblNormal};
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
const StyledTicketSummaryPhasePriceListWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledReservationSummaryTableName = styled.span``;

const StyledTicketSummaryPhasePriceCalculation = styled.span`
  margin-right: 0.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledReservationSummaryItemPrice = styled.span``;

const StyledReservationSummaryItemActionWrapper = styled.span`
  display: flex;
  flex-direction: row;
`;

const StyledReservationSummaryItemAction = styled.button`
  background: none;
  border: none;
  margin-left: 10px;
  cursor: pointer;
`;

const StyledTicketSummaryTotalTicketContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledTicketSummaryTotalTicketText = styled.h3`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.fonts.fontSizeLg};
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const StyledTicketSummaryTotalTicketCount = styled(
  StyledTicketSummaryTotalTicketText
)``;

const StyledTicketSummaryTotalAmountContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StyledTicketSummaryTotalAmountText = styled.h3`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.fonts.fontSizeLg};
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
const StyledTicketSummaryTotalAmountCount = styled(
  StyledTicketSummaryTotalAmountText
)``;

export {
  StyledReservationSummaryContainer,
  StyledTicketSummaryWrapper,
  StyledTicketSummaryEventTitle,
  StyledHorizontalLine,
  StyledTicketSummaryDate,
  StyledReservationSummaryItemWrapper,
  StyledReservationSummaryItem,
  StyledTicketSummaryPhasePriceListItem,
  StyledReservationSummaryTableName,
  StyledTicketSummaryPhasePriceCalculation,
  StyledTicketSummaryTotalTicketContainer,
  StyledTicketSummaryTotalTicketText,
  StyledTicketSummaryTotalTicketCount,
  StyledTicketSummaryTotalAmountCount,
  StyledTicketSummaryTotalAmountContainer,
  StyledTicketSummaryTotalAmountText,
  StyledTicketSummaryPhasePriceListWrapper,
  StyledReservationSummaryItemActionWrapper,
  StyledReservationSummaryItemAction,
  StyledReservationSummaryItemPrice
};
