import styled from "styled-components";

interface Props {
  $borderRadius: string;
  $accent: boolean;
  $margin: Margin;
  $padding: Padding;
}

const StyledCard = styled.div<Props>`
  background: #fff;
  box-shadow: ${(props) =>
    props.$accent
      ? props.theme.shadows.cardBoxAccentShadow
      : props.theme.shadows.cardBoxShadow};
  margin: ${({ $margin: margin }) =>
    `${margin.top} ${margin.right} ${margin.bottom} ${margin.left}`};
  padding: ${({ $padding: padding }) =>
    `${padding.top} ${padding.right} ${padding.bottom} ${padding.left}`};
  border-radius: ${({ $borderRadius }) => $borderRadius};
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;

export default StyledCard;
