import styled from "styled-components";

const StyledSeatControlButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledSeatControlButton = styled.button`
  border: none;
  background: none;

  &:not(:last-child) {
    margin-right: 12px;
  }
`;

export { StyledSeatControlButtonWrapper, StyledSeatControlButton };
