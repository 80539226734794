import React from "react";
import {
  DownloadTicketButton,
  StyledPaySuccessTicketCard,
  SuccessTicketContent,
  SuccessTicketContentOverlay,
  SuccessTicketDescription,
  SuccessTicketHead,
  SuccessTicketQRCode,
  SuccessTicketQRImage,
  TickerBuyerName,
  TicketDetailCompartment,
  TicketInvoiceConclusionTile,
  TicketInvoiceConclusionTileLeading,
  TicketInvoiceConclusionTileTrailing,
  TicketInvoiceList,
  TicketInvoiceListTile,
  TicketInvoiceListTileLeading,
  TicketInvoiceListTileTrailing,
  TicketName,
  TicketPurchaseDate,
  TicketShape,
  TicketSuccessCompartment
} from "@/components/ui/PaySuccessTicketCard/PaySuccessTicketCard.styled.ts";
import PayStatusIconWrapper from "@/components/ui/PayStatusIconWrapper";
import Line from "@/components/ui/Line";
import ticketShape from "@/assets/shapes/success-ticket-shape.svg";
import useBookingTicketPDF from "@/hooks/useBookingTicketPDF.ts";

interface Props {
  data: PaymentCheckoutResponse;
}

const PaySuccessTicketCard: React.FunctionComponent<Props> = ({ data }) => {
  const { data: pdf, isLoading } = useBookingTicketPDF(
    String(localStorage.getItem("bookingId"))
  );

  const handleDownload = () => {
    if (pdf) {
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(pdf);
      downloadLink.download = `${data.venue}-${data.fullname}.pdf`;
      downloadLink.click();
    }
  };

  return (
    <StyledPaySuccessTicketCard>
      <TicketShape alt={""} src={ticketShape} />
      <SuccessTicketContentOverlay>
        <SuccessTicketContent>
          <TicketSuccessCompartment>
            <PayStatusIconWrapper status={"SUCCESS"}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="32"
                viewBox="0 0 33 32"
                fill="none"
              >
                <path
                  d="M16.5003 2.66669C9.15366 2.66669 3.16699 8.65335 3.16699 16C3.16699 23.3467 9.15366 29.3334 16.5003 29.3334C23.847 29.3334 29.8337 23.3467 29.8337 16C29.8337 8.65335 23.847 2.66669 16.5003 2.66669ZM22.8737 12.9334L15.3137 20.4934C15.127 20.68 14.8737 20.7867 14.607 20.7867C14.3403 20.7867 14.087 20.68 13.9003 20.4934L10.127 16.72C9.74032 16.3334 9.74032 15.6934 10.127 15.3067C10.5137 14.92 11.1537 14.92 11.5403 15.3067L14.607 18.3734L21.4603 11.52C21.847 11.1334 22.487 11.1334 22.8737 11.52C23.2603 11.9067 23.2603 12.5334 22.8737 12.9334Z"
                  fill="#865CD0"
                />
              </svg>
            </PayStatusIconWrapper>

            <SuccessTicketHead>Payment Success!</SuccessTicketHead>

            <SuccessTicketDescription>
              We sent an email to&nbsp;{data.email}&nbsp; with your order
              confirmation.
            </SuccessTicketDescription>

            <SuccessTicketQRImage
              src={`data:image/png;base64, ${data.qr_reservation_code}`}
              alt={"QR"}
              width={80}
              height={80}
            />

            <SuccessTicketQRCode>{data.reservation_code}</SuccessTicketQRCode>
          </TicketSuccessCompartment>
          <TicketDetailCompartment>
            <TicketName>{data.reservation_schedule}</TicketName>
            <Line variant={"NORMAL"} stroke={"1px"} />
            <TickerBuyerName>Name: {data.fullname}</TickerBuyerName>
            <TicketPurchaseDate>{data.venue}</TicketPurchaseDate>
            {data.seats.map((seat, i) => (
              <TicketInvoiceList key={i}>
                <TicketInvoiceListTile>
                  <TicketInvoiceListTileLeading>
                    {seat.seat}
                  </TicketInvoiceListTileLeading>

                  <TicketInvoiceListTileTrailing>
                    Rs. {parseFloat(seat.amount).toLocaleString("en-In")}
                  </TicketInvoiceListTileTrailing>
                </TicketInvoiceListTile>
              </TicketInvoiceList>
            ))}

            <Line variant={"NORMAL"} stroke={"1px"} />

            <TicketInvoiceConclusionTile>
              <TicketInvoiceConclusionTileLeading>
                Total Reservations:
              </TicketInvoiceConclusionTileLeading>
              <TicketInvoiceConclusionTileTrailing>
                {data.seats.length}
              </TicketInvoiceConclusionTileTrailing>
            </TicketInvoiceConclusionTile>
            <TicketInvoiceConclusionTile>
              <TicketInvoiceConclusionTileLeading>
                Total Amount:
              </TicketInvoiceConclusionTileLeading>
              <TicketInvoiceConclusionTileTrailing>
                Rs. {parseFloat(data.total_amount).toLocaleString("en-In")}
              </TicketInvoiceConclusionTileTrailing>
            </TicketInvoiceConclusionTile>

            <DownloadTicketButton
              $isLoading={isLoading}
              onClick={handleDownload}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M18.2204 20.75H5.78043C5.43365 20.7359 5.09305 20.6535 4.77814 20.5075C4.46323 20.3616 4.18018 20.155 3.94519 19.8996C3.7102 19.6442 3.52788 19.3449 3.40867 19.019C3.28945 18.693 3.23568 18.3468 3.25043 18V15C3.25043 14.8011 3.32945 14.6103 3.4701 14.4697C3.61075 14.329 3.80152 14.25 4.00043 14.25C4.19934 14.25 4.39011 14.329 4.53076 14.4697C4.67141 14.6103 4.75043 14.8011 4.75043 15V18C4.72461 18.2969 4.81407 18.5924 5.00026 18.8251C5.18645 19.0579 5.45508 19.21 5.75043 19.25H18.2204C18.5158 19.21 18.7844 19.0579 18.9706 18.8251C19.1568 18.5924 19.2463 18.2969 19.2204 18V15C19.2204 14.8011 19.2995 14.6103 19.4401 14.4697C19.5808 14.329 19.7715 14.25 19.9704 14.25C20.1693 14.25 20.3601 14.329 20.5008 14.4697C20.6414 14.6103 20.7204 14.8011 20.7204 15V18C20.7504 18.6954 20.5045 19.3744 20.0363 19.8894C19.5681 20.4045 18.9155 20.7137 18.2204 20.75Z"
                  fill="#865CD0"
                />
                <path
                  d="M11.9998 15.75C11.9013 15.7505 11.8037 15.7312 11.7127 15.6935C11.6216 15.6557 11.5391 15.6001 11.4698 15.53L7.46985 11.53C7.33737 11.3878 7.26524 11.1998 7.26867 11.0055C7.2721 10.8112 7.35081 10.6258 7.48822 10.4884C7.62564 10.351 7.81102 10.2723 8.00532 10.2688C8.19963 10.2654 8.38767 10.3375 8.52985 10.47L11.9998 13.94L15.4698 10.47C15.612 10.3375 15.8001 10.2654 15.9944 10.2688C16.1887 10.2723 16.3741 10.351 16.5115 10.4884C16.6489 10.6258 16.7276 10.8112 16.731 11.0055C16.7344 11.1998 16.6623 11.3878 16.5298 11.53L12.5298 15.53C12.4606 15.6001 12.3781 15.6557 12.287 15.6935C12.196 15.7312 12.0984 15.7505 11.9998 15.75Z"
                  fill="#865CD0"
                />
                <path
                  d="M12 15.75C11.8019 15.7474 11.6126 15.6676 11.4725 15.5275C11.3324 15.3874 11.2526 15.1981 11.25 15V4C11.25 3.80109 11.329 3.61032 11.4697 3.46967C11.6103 3.32902 11.8011 3.25 12 3.25C12.1989 3.25 12.3897 3.32902 12.5303 3.46967C12.671 3.61032 12.75 3.80109 12.75 4V15C12.7474 15.1981 12.6676 15.3874 12.5275 15.5275C12.3874 15.6676 12.1981 15.7474 12 15.75Z"
                  fill="#865CD0"
                />
              </svg>
              Download Ticket
            </DownloadTicketButton>
          </TicketDetailCompartment>
        </SuccessTicketContent>
      </SuccessTicketContentOverlay>
    </StyledPaySuccessTicketCard>
  );
};

export default PaySuccessTicketCard;
