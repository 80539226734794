import styled from "styled-components";
import { NoCopyCss } from "@/assets/styles/styles.ts";

const StyledAreaSelector = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.75rem 1rem;

  border-radius: 5px;
  background: #fff;

  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
`;

const StyledAreaSelectorText = styled.span`
  font-family: Lora, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-right: auto;

  ${NoCopyCss}
`;

const StyledAreaSelectorOptionWrapper = styled.div`
  position: absolute;
  z-index: 10;
  width: 100%;
  margin-top: 0.25rem;
  padding: 0.75rem 0.75rem 1rem;
  border-radius: 5px;
  background: #fff;

  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
`;

const StyledAreaSelectorOptions = styled.div`
  overflow-y: scroll;
  max-height: 9rem;
`;

const StyledAreaSelectorOption = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  text-align: center;
  font-family: Lora, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  &:not(:last-child) {
    margin-bottom: 0.75rem;
  }

  cursor: pointer;

  ${NoCopyCss}
`;

const StyledAreaSelectorOptionInputLabel = styled.span``;

const StyledAreaSelectorOptionInput = styled.input`
  &[type="checkbox"] {
    accent-color: ${({ theme }) => theme.colors.primary};
  }

  &[type="checkbox"]:checked + ${StyledAreaSelectorOptionInputLabel} {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

type Props = {
  $isOpen: boolean;
};

const StyledAreaSelectorWrapper = styled.div<Props>`
  position: relative;
  height: fit-content;
  margin-bottom: 0.75rem;

  ${StyledAreaSelectorOptionWrapper} {
    display: ${({ $isOpen }) => ($isOpen ? "block" : "none")};
  }

  ${StyledAreaSelector} {
    border: 0.8px solid
      ${({ $isOpen, theme }) => ($isOpen ? theme.colors.primary : "#d9d9d9")};
  }

  ${StyledAreaSelector} > svg > path {
    stroke: ${({ $isOpen, theme }) =>
      $isOpen ? theme.colors.primary : "rgba(0, 0, 0, 0.5)"};
  }

  ${StyledAreaSelectorText} {
    color: ${({ $isOpen, theme }) =>
      $isOpen ? theme.colors.primary : "rgba(0, 0, 0, 0.5)"};
  }
`;

export {
  StyledAreaSelectorWrapper,
  StyledAreaSelectorText,
  StyledAreaSelector,
  StyledAreaSelectorOptionWrapper,
  StyledAreaSelectorOptions,
  StyledAreaSelectorOption,
  StyledAreaSelectorOptionInput,
  StyledAreaSelectorOptionInputLabel
};
