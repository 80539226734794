import React from "react";
import BubbleWaveGif from "@/assets/GIF/bubbleWaveLoading.gif";
import {
  StyledBufferLoading,
  StyledBufferLoadingLabels,
  StyledBufferLoadingWrapper
} from "@/components/layouts/PaymentBufferLayout/PaymentBufferLayout.styled.ts";
import Container from "@/components/ui/Container";
import { Outlet } from "react-router-dom";

const PaymentBufferLayout: React.FunctionComponent = () => {
  return (
    <StyledBufferLoadingWrapper>
      <StyledBufferLoading src={BubbleWaveGif} alt="Loading..." />

      <Container>
        <StyledBufferLoadingLabels>
          The payment is being proceed.. so please sit down and relax..
        </StyledBufferLoadingLabels>
      </Container>
      <>
        <Outlet />
      </>
    </StyledBufferLoadingWrapper>
  );
};

export default PaymentBufferLayout;
