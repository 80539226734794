import styled from "styled-components";

const StyledBufferLoadingWrapper = styled.div`
  width: 100%;
  position: relative;
  height: 100%;
`;

const StyledBufferLoadingLabels = styled.p`
  padding-top: 10rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.inputPlaceholderColor};
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const StyledBufferLoading = styled.img`
  position: absolute;
  inset: 0;
  margin: auto;
`;

export {
  StyledBufferLoadingWrapper,
  StyledBufferLoadingLabels,
  StyledBufferLoading
};
