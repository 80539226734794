import styled from "styled-components";

const StyledBusinessBrandLogo = styled.img`
  border-radius: 5px;
  height: 80px;
  aspect-ratio: 1/1;
  object-fit: cover;
`;

const StyledBusinessBrandLogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
`;

const StyledBusinessBrandEventName = styled.div`
  color: #000;
  font-family: Lora, serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const StyledBusinessBrandName = styled.div`
  color: #000;
  font-family: Lora, serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const StyledBusinessBrandAddress = styled.div`
  color: rgba(0, 0, 0, 0.7);
  font-family: Lora, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const StyledBusinessBrandSocialLinks = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
`;

const StyledBusinessBrandSocialLinkItem = styled.a``;

const StyledBusinessBrandInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.35rem;
`;

const StyledBusinessBrandSocialLinkIcon = styled.img`
  height: 16px;
  aspect-ratio: 1/1;
  object-fit: cover;
`;

const StyledBusinessBrandBar = styled.div`
  margin-top: 0.2rem;
  margin-bottom: 0.75rem;
  display: flex;
  flex-direction: row;
  gap: 0.35rem;
  align-items: center;
  width: 100%;
  height: 90px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 2px 6px 0 rgba(134, 92, 208, 0.5);
`;

export {
  StyledBusinessBrandBar,
  StyledBusinessBrandLogoWrapper,
  StyledBusinessBrandLogo,
  StyledBusinessBrandEventName,
  StyledBusinessBrandName,
  StyledBusinessBrandAddress,
  StyledBusinessBrandSocialLinks,
  StyledBusinessBrandSocialLinkItem,
  StyledBusinessBrandSocialLinkIcon,
  StyledBusinessBrandInfo
};
