import { create } from "zustand";
import { mountStoreDevtool } from "simple-zustand-devtools";
import TableAPIModel from "@/apiModels/TableAPIModel";

// import { E164Number } from "libphonenumber-js";

interface BulkTableCartStore {
  reservationTables: TableAPIModel[];
  setReservationTables: (tables: TableAPIModel[]) => void;
  full_name: string;
  setFullName: (name: string) => void;
  phone_number: string;
  setPhoneNumber: (number: string) => void;
  email: string;
  setEmail: (email: string) => void;
  checkin_time: string;
  setCheckinTime: (time: string) => void;
  reserved_date: Date;
  setReservedDate: (date: Date) => void;
  removeReservationTableById: (id: string) => void;
  totalAmount: number;
  setTotalAmount: (amount: number) => void;
}

const useBulkTableCartStore = create<BulkTableCartStore>((set) => ({
  reservationTables: [],
  setReservationTables: (tables) => set({ reservationTables: tables }),
  full_name: "",
  setFullName: (name) => set({ full_name: name }),
  // phone_number: {} as E164Number,
  phone_number: "",
  setPhoneNumber: (number) => set({ phone_number: number }),
  email: "",
  setEmail: (email) => set({ email }),
  checkin_time: "",
  setCheckinTime: (time) => set({ checkin_time: time }),
  reserved_date: new Date(),
  setReservedDate: (date) => set({ reserved_date: date }),
  removeReservationTableById: (id) =>
    set((state) => ({
      reservationTables: state.reservationTables.filter(
        (table) => table.id !== id
      )
    })),
  totalAmount: 0,
  setTotalAmount: (amount) => set({ totalAmount: amount })
}));

if (import.meta.env.MODE === "development") {
  mountStoreDevtool("Bulk Cart Store", useBulkTableCartStore);
}

export default useBulkTableCartStore;
