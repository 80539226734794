import styled from "styled-components";

const StyledInquiryWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 10fr;
  place-items: start start;
  grid-template-rows: 1fr;
  grid-column-gap: 22px;
  grid-row-gap: 0;
`;

const StyledInfoIcon = styled.img`
  grid-area: 1 / 1 / 2 / 2;
`;

const StyledInquiryDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 12px;
  grid-area: 1 / 2 / 2 / 3;
  margin-top: 10px;
  margin-left: -5px;
`;

const StyledInquiryDetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ theme }) => theme.colors.darkSlateColor};
`;

const StyledInquiryDetailsContent = styled.a<{ $heading?: boolean }>`
  color: ${({ theme }) => theme.colors.darkSlateColor};
  font-size: ${(props) => (props.$heading ? "20px" : "14px")};
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  text-transform: ${(props) => (props.$heading ? "uppercase" : "none")};
`;

const StyledInquiryDetailsContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

const StyledInquiryServiceIcon = styled.img``;

export {
  StyledInquiryWrapper,
  StyledInfoIcon,
  StyledInquiryDetailsWrapper,
  StyledInquiryDetails,
  StyledInquiryDetailsContent,
  StyledInquiryDetailsContentWrapper,
  StyledInquiryServiceIcon
};
