import styled from "styled-components";
import { NoScrollBarCss } from "@/assets/styles/styles.ts";

const StyledRootLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100dvh;
  position: relative;
`;

const StyledHeader = styled.div``;

const StyledBody = styled.div`
  flex: 1;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-top: 0.5rem;

  ${NoScrollBarCss}
`;

export { StyledRootLayout, StyledHeader, StyledBody };
