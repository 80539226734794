import styled from "styled-components";

const StyledFailureCardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 0.25rem 2rem;
`;

const StyledFailureHead = styled.h2`
  color: ${({ theme }) => theme.colors.danger};
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

const StyledFailureDescription = styled.p`
  color: rgba(46, 46, 46, 0.5);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const StyledTryAgainButton = styled.button`
  background: ${({ theme }) => theme.colors.primaryLight};
  color: ${({ theme }) => theme.colors.primary};

  border: none;
  border-radius: 0.5rem;
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  padding: 10px 0;

  cursor: pointer;
`;

export {
  StyledFailureCardContent,
  StyledFailureHead,
  StyledFailureDescription,
  StyledTryAgainButton
};
