import styled from "styled-components";

const StyledSectionTitle = styled.h1`
  color: ${({ theme }) => theme.colors.black};
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  max-width: 320px;
`;

export { StyledSectionTitle };
