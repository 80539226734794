import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from "react";
import Card from "@/components/ui/Card";
import {
  StyledPaymentInputLabel,
  StyledPaymentLabelImage,
  StyledPaymentMethodPortalCard,
  StyledPaymentMethodsWrapper,
  StyledPaymentRadioInput
} from "@/components/ui/PaymentMethodEntry/PaymentMethodEntry.styled.ts";

import KhaltiIcon from "@/assets/icons/KhaltiIcon.svg";
import ESewaIcon from "@/assets/icons/ESewaIcon.svg";
import EPayV2, { EPayV2Ref } from "@/components/ui/EPayV2";
import usePaymentProviderConfig from "@/hooks/usePaymentProviderConfig.ts";
import getDecrypted from "@/utils/getDecrypted.ts";
import FonePayIcon from "@/assets/icons/FonePayIcon.svg";
import IMEPayIcon from "@/assets/icons/IMEPayIcon.svg";
import useKhaltiInit from "@/hooks/useKhaltiInit.ts";
import useBulkTableCartStore from "@/stores/BulkTableCartStore.ts";
import EsewaProviderConfig from "@/apiModels/PaymentModels/EsewaProviderConfig.ts";
import { toast } from "react-toastify";

export interface PaymentMethodRef {
  initPayment: () => void;
}

type Props = {
  normalUser: string;
  businessExternalId: string;
  reservationId: string;
};

interface IPaymentPortalItem {
  readonly name: string;
  readonly icon: string;
  readonly value: string;
}

const paymentPortals: IPaymentPortalItem[] = [
  {
    name: "Khalti",
    icon: KhaltiIcon,
    value: "KHALTI"
  },
  {
    name: "Esewa",
    icon: ESewaIcon,
    value: "ESEWA"
  },
  {
    name: "FonePay",
    icon: FonePayIcon,
    value: "FONEPAY"
  },
  {
    name: "IMEPay",
    icon: IMEPayIcon,
    value: "IMEPAY"
  }
] as const;

const PaymentMethodEntry: React.ForwardRefExoticComponent<
  Props & React.RefAttributes<PaymentMethodRef>
> = forwardRef<PaymentMethodRef, Props>(
  ({ reservationId, businessExternalId, normalUser }, ref) => {
    const ePayRef = useRef<EPayV2Ref>(null);

    const [esewaProviderConfig, setEsewaProviderConfig] =
      useState<EsewaProviderConfig | null>(null);
    const khaltiInit = useKhaltiInit({
      cbError: () => {},
      cb: () => {},
      cbSuccess: (data) => {
        if (data && data.payment_url) {
          window.open(data.payment_url, "_self");
        }
      },
      businessId: businessExternalId
    });

    const { data: paymentProviderConfig, isLoading } = usePaymentProviderConfig(
      {
        businessExternalId: businessExternalId
      }
    );

    const { fullName, email, phoneNumber, totalAmount } = useBulkTableCartStore(
      (store) => ({
        fullName: store.full_name,
        email: store.email,
        phoneNumber: store.phone_number,
        totalAmount: store.totalAmount
      })
    );

    const [availablePaymentPortals, setAvailablePaymentPortals] = useState<
      IPaymentPortalItem[]
    >([]);

    const [selectedPaymentPortal, setSelectedPaymentPortal] = useState(() => {
      return localStorage.getItem("paymentMethod") || "";
    });

    useEffect(() => {
      if (!isLoading && !!paymentProviderConfig) {
        paymentProviderConfig.map((p) => {
          if (p.provider_name.toUpperCase() === "ESEWA") {
            setEsewaProviderConfig(
              JSON.parse(
                String(getDecrypted(p.provider_config))
              ) as unknown as EsewaProviderConfig
            );
          }
        });

        const availablePortals = paymentPortals.filter((portal) =>
          paymentProviderConfig.some(
            (p) => p.provider_name.toUpperCase() === portal.value.toUpperCase()
          )
        );
        setAvailablePaymentPortals(availablePortals);
      }
    }, [isLoading, paymentProviderConfig]);

    useEffect(() => {
      localStorage.setItem("paymentMethod", selectedPaymentPortal);
    }, [selectedPaymentPortal]);

    const onPaymentPortalChangeHandler = (value: string) => {
      setSelectedPaymentPortal(value);
    };

    const initiateKhaltiPay = () => {
      khaltiInit.mutate({
        fullname: fullName,
        amount: totalAmount,
        email: email,
        feature: "seat-reservation",
        normal_user_id: normalUser,
        phone: phoneNumber,
        reference_id: reservationId,
        payment_provider_data: {
          return_url: `${import.meta.env.VITE_HOST_URL}/payment/khalti`
        }
      });
    };

    useImperativeHandle(ref, () => ({
      initPayment: () => {
        switch (selectedPaymentPortal) {
          case "ESEWA":
            if (esewaProviderConfig) {
              ePayRef.current?.submitForm();
            } else {
              toast.error("Esewa Provider not Found!");
            }
            break;
          case "KHALTI":
            initiateKhaltiPay();
            break;
          default:
            break;
        }
      }
    }));

    if (isLoading) {
      return <>Loading</>;
    }

    return (
      <Card
        accent={true}
        margin={{
          top: "1.25rem",
          bottom: "1.25rem"
        }}
        padding={{
          top: "0.625rem",
          left: "0.625rem",
          bottom: "0.625rem",
          right: "0.625rem"
        }}
      >
        <StyledPaymentMethodsWrapper>
          {availablePaymentPortals.map((portal) => (
            <StyledPaymentMethodPortalCard key={portal.name}>
              <StyledPaymentRadioInput
                type="radio"
                name={portal.name}
                value={portal.value}
                id={portal.name}
                checked={selectedPaymentPortal === portal.value}
                onChange={() => onPaymentPortalChangeHandler(portal.value)}
              />
              <StyledPaymentInputLabel htmlFor={portal.name}>
                <StyledPaymentLabelImage
                  alt={portal.name}
                  src={portal.icon}
                  height={24}
                  width={52}
                />
              </StyledPaymentInputLabel>
            </StyledPaymentMethodPortalCard>
          ))}
        </StyledPaymentMethodsWrapper>
        {esewaProviderConfig && (
          <EPayV2
            ref={ePayRef}
            reservationId={reservationId}
            config={esewaProviderConfig}
          />
        )}
      </Card>
    );
  }
);

export default PaymentMethodEntry;
