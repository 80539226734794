import styled from "styled-components";

const StyledConfirmReservationOutlineAction = styled.button`
  border-radius: 5px;
  background: none;
  color: ${({ theme }) => theme.colors.primary};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  padding: 10px 40px;
`;

const StyledConfirmReservationFilledAction = styled.button`
  border: none;
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.primary};
  padding: 10px 40px;
`;

export {
  StyledConfirmReservationOutlineAction,
  StyledConfirmReservationFilledAction
};
