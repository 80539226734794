import React from "react";
import {
  StyledBottomBar,
  StyledBottomBarActionButton,
  StyledBottomBarContent,
  StyledBottomBarPrice,
  StyledBottomBarPriceWrapper,
  StyledBottomBarTotalTicket
} from "@/components/ui/BottomBar/BottomBar.styled.ts";
import CrossProgressIndicator from "@/components/ui/CrossProgressIndicator";

type Props = {
  isSeatSelected: boolean;
  buttonLabel: string;
  onClick: () => void;
  isDisabled: boolean;
  isLoading: boolean;
  price: number;
  totalReservation: number;
};

const BottomBar: React.FunctionComponent<Props> = ({
  isSeatSelected,
  price,
  totalReservation,
  onClick,
  isDisabled,
  isLoading,
  buttonLabel
}) => {
  return (
    <StyledBottomBar>
      <StyledBottomBarContent $isSeatSelected={isSeatSelected}>
        {isSeatSelected && (
          <StyledBottomBarPriceWrapper>
            <StyledBottomBarPrice>
              Rs. {price.toLocaleString("en-In")}
            </StyledBottomBarPrice>
            <StyledBottomBarTotalTicket>
              Total Reservations:&nbsp;
              <span>{totalReservation}</span>
            </StyledBottomBarTotalTicket>
          </StyledBottomBarPriceWrapper>
        )}
        <StyledBottomBarActionButton
          onClick={onClick}
          disabled={isDisabled}
          $isLoading={isLoading}
        >
          {isLoading ? (
            <>
              <CrossProgressIndicator />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Loading
            </>
          ) : (
            buttonLabel
          )}
        </StyledBottomBarActionButton>
      </StyledBottomBarContent>
    </StyledBottomBar>
  );
};

export default BottomBar;
