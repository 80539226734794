import React from "react";
import Base64 from "crypto-js/enc-base64";
import Utf8 from "crypto-js/enc-utf8";
import { useSearchParams } from "react-router-dom";
import useESewaCheckout from "@/hooks/useESewaCheckout.ts";

interface ESewaSuccessResponse {
  transaction_code: string;
  status: string;
  total_amount: string;
  transaction_uuid: string;
  product_code: string;
  signed_field_names: string;
  signature: string;
}

const decodeToESewaTransactionSuccessObj = (raw: string) => {
  const parsedData = Base64.parse(raw);
  const result = Utf8.stringify(parsedData);
  return JSON.parse(result) as unknown as ESewaSuccessResponse;
};

const ESewaBufferView: React.FunctionComponent = () => {
  const [searchParams] = useSearchParams();

  const checkout = useESewaCheckout({
    cb: () => {},
    cbSuccess: (data) => {
      if (data) {
        window.location.replace(`/payment/checkout/success/?ref=${data.id}`);
      }
    },
    cbError: () => {
      window.location.replace(`/payment/checkout/failure`);
    }
  });

  const data = searchParams.get("data");

  if (data) {
    const condition = localStorage.getItem("buffer");
    if (!condition) {
      const transactionSuccessObj = decodeToESewaTransactionSuccessObj(
        data as string
      );
      checkout.mutate({
        businessId: localStorage.getItem("businessId") as string,
        payload: {
          reference_id: localStorage.getItem("bookingId") as string,
          feature: import.meta.env.VITE_FEATURE,
          normal_user_id: localStorage.getItem("normalUser") as string,
          amount: transactionSuccessObj.total_amount.replace(/,/g, ""),
          payment_provider_data: {
            amount: transactionSuccessObj.total_amount.replace(/,/g, ""),
            transaction_uuid: transactionSuccessObj.transaction_uuid
          }
        }
      });
      localStorage.setItem("buffer", "true");
    }
  }

  return <></>;
};

export default ESewaBufferView;
