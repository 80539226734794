import styled from "styled-components";

const StyledDeleteResHead = styled.h2`
  color: ${({ theme }) => theme.colors.black};
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const StyledDeleteResDescription = styled.p`
  color: ${({ theme }) => theme.colors.inputPlaceholderColor};
  margin: 12px 0;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  span {
    color: ${({ theme }) => theme.colors.danger};
  }
`;

const StyledOutlineCancelButton = styled.button`
  border-radius: 5px;
  background: none;
  color: #e53535;
  border: 1px solid #e53535;
`;

const StyledFilledConfirmButton = styled.button`
  border: none;
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.white};
  background: #e53535;
`;

const StyledDeleteResActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 12px;

  ${StyledOutlineCancelButton}, ${StyledFilledConfirmButton} {
    cursor: pointer;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 10px 40px;
  }
`;

export {
  StyledDeleteResHead,
  StyledDeleteResDescription,
  StyledDeleteResActionWrapper,
  StyledOutlineCancelButton,
  StyledFilledConfirmButton
};
