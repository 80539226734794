import styled from "styled-components";

const StyledDownloadAppButton = styled.button`
  border: none;
  box-shadow: ${({ theme }) => theme.shadows.buttonShadow};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primaryLight};
  width: 100%;
  color: ${({ theme }) => theme.colors.primary};
  border-radius: 8px;
  margin: 1.25rem 0;
  padding: 0.5rem 0;

  font-family: Lora, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;

  & > * {
    margin-right: 0.5rem;
  }
`;

export default StyledDownloadAppButton;
