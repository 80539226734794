import styled from "styled-components";

interface EmptyErrorProps {
  $emptyError: string;
}

const PersonalDetailFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const PersonalDetailInputField = styled.input<EmptyErrorProps>`
  height: 40px;
  padding: 16px;
  border-radius: 5px;
  //border: 0.8px solid #D9D9D9;
  border: 0.8px solid
    ${(EmptyErrorProps) =>
      EmptyErrorProps.$emptyError ? "#F43434" : "#D9D9D9"};
  outline: none;
  background: #fff;
  box-shadow: ${({ theme }) => theme.shadows.inputFieldShadow};

  &:focus {
    border: 2px solid ${({ theme }) => theme.colors.primary};
  }
`;

const PersonalDetailLabel = styled.label`
  color: ${({ theme }) => theme.colors.black};
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const PersonalDetailRequiredIndicator = styled(PersonalDetailLabel)`
  color: ${({ theme }) => theme.colors.danger};
  margin-left: 5px;
`;

const StyledInputFieldError = styled.h4<{ $ticketsError?: boolean }>`
  color: ${({ theme }) => theme.colors.danger};
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-size: ${({ theme }) => theme.fonts.fontSizeSm};
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  margin-top: ${(props) => (props.$ticketsError ? "10px" : "0")};
`;
const PersonalDetailPhoneInputWrapper = styled.div<EmptyErrorProps>`
  height: 40px;
  padding: 0;
  border: 0.8px solid
    ${(EmptyErrorProps) =>
      EmptyErrorProps.$emptyError ? "#F43434" : "#D9D9D9"};
  box-shadow: ${({ theme }) => theme.shadows.inputFieldShadow};
  border-radius: 5px;

  &:focus-within {
    border: 2px solid ${({ theme }) => theme.colors.primary};
  }

  & .PhoneInput {
    height: 100%;
    border-radius: 5px;
    outline: none;
  }

  & .PhoneInputCountry {
    height: 100%;
    padding: 0 14px;
    margin: 0 !important;
    border: none;
    gap: 5px;
    // &:focus{
    //   border: 0.8px solid ${({ theme }) => theme.colors.primary};
    // }
  }

  & .PhoneInputInput {
    padding: 0 15px;
    //border: none;
    border-radius: 0 5px 5px 0;
    margin-left: 2px;
    height: 100%;
    border-left: 1px solid #d9d9d9;
    border-right: none;
    border-top: none;
    border-bottom: none;
    outline: none;
    // &:focus{
    //   border: 2px solid ${({ theme }) => theme.colors.primary};
    // }
  }

  & .PhoneInputCountryIcon--border {
    box-shadow: none !important;
  }

  & .PhoneInputCountrySelectArrow {
    margin-left: 0;
  }

  & .PhoneInputCountryIcon {
    width: 86%;
    height: 100%;
    padding: 5px 0;
    background-color: white;
  }
`;

const Note = styled.h1`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.fonts.fontSizeLg};
  font-style: normal;
  font-weight: 400;
  line-height: ${({ theme }) => theme.lineHeights.lineHeightSm};
  letter-spacing: ${({ theme }) => theme.letterSpacings.letterSpacingSm};
`;

export {
  PersonalDetailFieldWrapper,
  PersonalDetailPhoneInputWrapper,
  PersonalDetailInputField,
  PersonalDetailLabel,
  PersonalDetailRequiredIndicator,
  Note,
  StyledInputFieldError
};
