import { createBrowserRouter, Navigate } from "react-router-dom";
import RootLayout from "@/components/layouts/RootLayout";
import PublicLayout from "@/components/layouts/PublicLayout";
import BehindPaywallLayout from "@/components/layouts/BehindPaywallLayout";
import QRPageLayout from "@/components/layouts/QRPageLayout";
import PaymentLayout from "@/components/layouts/PaymentLayout";
import QRSeatBookingPage from "@/views/QRView/QRSeatBookingPage";
import NotFoundErrorPage from "@/components/errors";
import ESewaBufferView from "@/views/PaymentView/Buffer/ESewa";
import KhaltiBufferView from "@/views/PaymentView/Buffer/Khalti";
import SuccessView from "@/views/PaymentView/SuccessView";
import FailureView from "@/views/PaymentView/FailureView";
import ForMoreInquiry from "@/components/ui/ForMoreInquiry";
import PaymentBufferLayout from "@/components/layouts/PaymentBufferLayout";
import Container from "@/components/ui/Container";
import PaymentCheckoutLayout from "@/components/layouts/PaymentCheckoutLayout";

const routes = createBrowserRouter([
  {
    element: <RootLayout />,
    children: [
      {
        element: <PublicLayout />,
        errorElement: <NotFoundErrorPage />,
        children: [
          {
            path: "/",
            element: (
              <Container>
                <ForMoreInquiry />
              </Container>
            )
          },
          {
            path: "qr",
            element: <QRPageLayout />,
            children: [
              {
                index: true,
                element: <Navigate to={"/"} />
              },
              {
                path: ":id",
                element: <QRSeatBookingPage />
              }
            ]
          }
        ]
      },
      {
        element: <BehindPaywallLayout />,
        children: [
          {
            path: "payment",
            element: <PaymentLayout />,
            children: [
              {
                element: <PaymentBufferLayout />,
                children: [
                  {
                    path: "esewa",
                    element: <ESewaBufferView />
                  },
                  {
                    path: "khalti",
                    element: <KhaltiBufferView />
                  }
                ]
              },
              {
                path: "checkout",
                element: <PaymentCheckoutLayout />,
                children: [
                  {
                    path: "success",
                    element: <SuccessView />
                  },
                  {
                    path: "failure",
                    element: <FailureView />
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
]);

export default routes;
