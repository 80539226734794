import React, { useEffect, useState, useTransition } from "react";
import { toast } from "react-toastify";
import TableAPIModel from "@/apiModels/TableAPIModel";
import theme from "@/data/theme";
import updateSeatColor from "@/utils/updateSeatColor.ts";
import useBulkTableCartStore from "@/stores/BulkTableCartStore.ts";
import { MapArea } from "@/data/venueMapData";

interface ISVGMapComponentProps {
  seats: TableAPIModel[];
  modelDispatcher: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedReservation: React.Dispatch<
    React.SetStateAction<TableAPIModel | null>
  >;
  venueMap: MapArea;
}

const SVGMapComponent: React.FunctionComponent<ISVGMapComponentProps> = ({
  seats,
  modelDispatcher,
  setSelectedReservation,
  venueMap
}) => {
  const [currentMapId, setCurrentMapId] = useState<string>(() => {
    return venueMap.mapId;
  });

  const [isPending, startTransition] = useTransition();

  const setReservationTables = useBulkTableCartStore(
    (store) => store.setReservationTables
  );
  const selectTable = (tableElement: HTMLElement) => {
    const foundSeat = seats.find((seat) => seat.serial_no === tableElement.id);

    if (foundSeat) {
      if (foundSeat.status === "booked") {
        toast.error("This table is already reserved");
        return;
      }

      if (foundSeat.status === "hold") {
        toast.warning("This table is on hold");
        return;
      }

      const resArray = JSON.parse(
        String(localStorage.getItem("selectedReservations"))
      ) as unknown as TableAPIModel[];

      const isSeatPresent = resArray.some(
        (seat) => seat.serial_no === foundSeat.serial_no
      );

      if (isSeatPresent) {
        updateSeatColor({
          seat: foundSeat.serial_no,
          color: ""
        });
        const updatedResArray = resArray.filter(
          (seat) => seat.serial_no !== foundSeat.serial_no
        );
        localStorage.setItem(
          "selectedReservations",
          JSON.stringify(updatedResArray)
        );
        setReservationTables(updatedResArray);
        return;
      }

      if (resArray.length >= 2) {
        toast.error("Only 2 seats can be reserved at a time");
        return;
      }

      setSelectedReservation(foundSeat);
      modelDispatcher(true);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleClick = (event: Event) => {
    const clickedElement = event.target as HTMLElement;
    const tableElement = clickedElement.closest(
      `g[id]:not([id=${venueMap!.mapId}])`
    ) as HTMLElement;

    if (tableElement) {
      selectTable(tableElement);
    }
  };

  useEffect(() => {
    startTransition(() => {
      const map = document.getElementById(currentMapId);

      if (map) {
        map.removeEventListener("click", handleClick);
      }
      setCurrentMapId(venueMap.mapId);
    });
  }, [venueMap, currentMapId, handleClick]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkForUnavailable = () => {
    seats.map((seat) => {
      switch (seat.status) {
        case "booked":
          updateSeatColor({
            seat: seat.serial_no,
            color: theme.colors.booked
          });
          break;
        case "hold":
          updateSeatColor({
            seat: seat.serial_no,
            color: theme.colors.hold
          });
          break;
        case "available":
          break;
        default:
          break;
      }

      const currentSelectedReservation = localStorage.getItem(
        "selectedReservations"
      );

      if (currentSelectedReservation) {
        const selectedReservations = JSON.parse(
          currentSelectedReservation
        ) as TableAPIModel[];

        selectedReservations.forEach((reservation) => {
          if (reservation.serial_no === seat.serial_no) {
            updateSeatColor({
              seat: reservation.serial_no,
              color: theme.colors.primary
            });
          }
        });
      }
    });
  };

  useEffect(() => {
    const map = document.getElementById(currentMapId);

    if (map) {
      map.addEventListener("click", handleClick);
      checkForUnavailable();
    }

    return () => {
      const map = document.getElementById(currentMapId);

      if (map) {
        map.removeEventListener("click", handleClick);
      }
    };
  }, [currentMapId, venueMap, handleClick, checkForUnavailable]);

  if (!venueMap && isPending) {
    return <></>;
  }

  return <>{venueMap.map}</>;
};

export default SVGMapComponent;
