import React from "react";

type Props = {
  id: string;
  name: string;
  value: string | number;
};

const EPayInputField: React.FunctionComponent<Props> = ({
  id,
  name,
  value
}) => {
  return (
    <input type="text" id={id} name={name} value={value} readOnly required />
  );
};

export default EPayInputField;
