import styled from "styled-components";
import { TableStatus } from "@/apiModels/TableAPIModel";

type Props = {
  $isDisabled: boolean;
};

const StyledQRSeatBookingPageContent = styled.div<Props>`
  ${({ $isDisabled }) =>
    $isDisabled &&
    `
        pointer-events: none;
    `}
`;

const StyledLegendWrapper = styled.div`
  display: flex;
  margin: 9px 0;
`;

type LegendProps = {
  $tableStatus: TableStatus;
};

const StyledLegend = styled.div<LegendProps>`
  display: flex;
  align-items: center;
  gap: 3px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  &::before {
    display: inline-block;
    content: "";
    border-radius: 3px;
    height: 16px;
    width: 16px;
    background: ${({ $tableStatus, theme }) =>
      $tableStatus === "available"
        ? theme.colors.available
        : $tableStatus === "booked"
          ? theme.colors.booked
          : theme.colors.hold};
  }

  &:not(:last-child) {
    margin-right: 12px;
  }
`;

export { StyledQRSeatBookingPageContent, StyledLegendWrapper, StyledLegend };
