import styled from "styled-components";

const StyledRevelQRLoading = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  background: ${({ theme }) => theme.colors.white};
`;

const StyledRevelQRLoadingGIF = styled.img`
  aspect-ratio: 1/1;
  height: fit-content;
  inset: 0;
  margin: auto;
`;

export { StyledRevelQRLoading, StyledRevelQRLoadingGIF };
