import React from "react";
import revelLoadingGif from "@/assets/GIF/revelLoading.gif";
import {
  StyledRevelQRLoading,
  StyledRevelQRLoadingGIF
} from "@/components/ui/RevelQrLoading/RevelQrLoading.styled.ts";

const RevelQrLoading: React.FunctionComponent = () => {
  return (
    <StyledRevelQRLoading>
      <StyledRevelQRLoadingGIF src={revelLoadingGif} alt={""} />
    </StyledRevelQRLoading>
  );
};

export default RevelQrLoading;
