import React from "react";
import Modal from "react-modal";
import {
  StyledInfoModalActionButton,
  StyledInfoModalActionWrapper,
  StyledInfoModalDescription,
  StyledInfoModalHead,
  StyledInfoModalTableDetail,
  StyledInfoModalTableDetailLabel,
  StyledInfoModalTableDetailValue,
  StyledInfoModalTableDetailWrapper
} from "@/components/ui/Modals/ReservationInfoModal/ReservationInfoModal.styled.ts";
import TableAPIModel from "@/apiModels/TableAPIModel";
import theme from "@/data/theme";
import parse from "html-react-parser";

interface IReservationInfoModalProps {
  isOpen: boolean;
  onOk: () => void;
  reservation: TableAPIModel;
}

const ReservationInfoModal: React.FunctionComponent<
  IReservationInfoModalProps
> = ({ isOpen, onOk, reservation }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onOk}
      style={{
        content: {
          maxWidth: "320px",
          minWidth: "296px",
          height: "fit-content",
          borderRadius: "10px",
          padding: "25px 10px",
          inset: "0",
          margin: "auto",
          boxShadow: "0px 2px 6px 0px rgba(134, 92, 208, 0.50)"
        },
        overlay: {
          background: theme.colors.modalBackground
        }
      }}
    >
      <StyledInfoModalHead>Details</StyledInfoModalHead>
      <StyledInfoModalDescription>
        {parse(String(reservation.features))}
      </StyledInfoModalDescription>
      <StyledInfoModalTableDetailWrapper>
        <StyledInfoModalTableDetail>
          <StyledInfoModalTableDetailLabel>
            Table:&nbsp;
          </StyledInfoModalTableDetailLabel>
          <StyledInfoModalTableDetailValue>
            {reservation.serial_no}
          </StyledInfoModalTableDetailValue>
        </StyledInfoModalTableDetail>

        <StyledInfoModalTableDetail>
          <StyledInfoModalTableDetailLabel>
            Pax:&nbsp;
          </StyledInfoModalTableDetailLabel>
          <StyledInfoModalTableDetailValue>
            {reservation.pax}
          </StyledInfoModalTableDetailValue>
        </StyledInfoModalTableDetail>
      </StyledInfoModalTableDetailWrapper>
      <StyledInfoModalTableDetailWrapper>
        <StyledInfoModalTableDetail>
          <StyledInfoModalTableDetailLabel>
            Total Amount:&nbsp;
          </StyledInfoModalTableDetailLabel>
          <StyledInfoModalTableDetailValue>
            Rs. {Number(reservation.price).toLocaleString("en-In")}
          </StyledInfoModalTableDetailValue>
        </StyledInfoModalTableDetail>
      </StyledInfoModalTableDetailWrapper>
      <StyledInfoModalActionWrapper>
        <StyledInfoModalActionButton onClick={onOk}>
          ok
        </StyledInfoModalActionButton>
      </StyledInfoModalActionWrapper>
    </Modal>
  );
};

export default ReservationInfoModal;
