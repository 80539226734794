import CryptoJS from "crypto-js";

const SECRET_KEY = import.meta.env.VITE_SECRET_KEY;
const SECRET_IV = import.meta.env.VITE_SECRET_IV;

const getDecrypted = (encrypted: string) => {
  const key = CryptoJS.enc.Base64.parse(SECRET_KEY);
  const iv = CryptoJS.enc.Base64.parse(SECRET_IV);

  const decrypted = CryptoJS.AES.decrypt(encrypted, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });

  return decrypted.toString(CryptoJS.enc.Utf8);
};

export default getDecrypted;
