import styled from "styled-components";

const StyledNavbar = styled.nav`
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 2.75rem;
  padding: 0 1rem;
  //padding: 0.75rem 1rem;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
  background: #fff;

  & > img {
    height: 18px;
    aspect-ratio: 124/24;
  }
`;

export { StyledNavbar };
