import { css } from "styled-components";

const NoCopyCss = css`
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const NoScrollBarCss = css`
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export { NoCopyCss, NoScrollBarCss };
