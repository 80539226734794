import { useMutation } from "@tanstack/react-query";
import APIClient from "@/services/APIClient";
import BaseServices from "@/services/BaseServices";

const useESewaCheckout = ({
  cb,
  cbSuccess,
  cbError
}: IMutationHookProps<EsewaCheckoutResponse>) => {
  return useMutation<
    EsewaCheckoutResponse,
    CustomError<RevelErrorResponse>,
    PaymentCheckoutRequest<ESewaCheckoutPayload>
  >({
    mutationFn: ({ businessId, payload }) => {
      const apiClient = new APIClient<ESewaCheckoutPayload>(
        BaseServices.PAYMENT_SERVICE,
        `/business/${businessId}/payment/esewa-web/checkout`
      );
      return apiClient.post<EsewaCheckoutResponse>(payload);
    },
    onMutate: cb,
    onSuccess: cbSuccess,
    onError: cbError
  });
};

export default useESewaCheckout;
