import styled from "styled-components";

type Props = {
  status: "SUCCESS" | "FAIL";
};

const StyledPayStatusIconWrapper = styled.div<Props>`
  display: flex;
  background: ${(props) =>
    props.status === "SUCCESS"
      ? props.theme.colors.primaryXXLight
      : props.theme.colors.dangerLight};
  border-radius: 100%;
  height: 56px;
  width: 56px;
  justify-content: center;
  align-items: center;
`;

export default StyledPayStatusIconWrapper;
