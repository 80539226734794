import React from "react";

const CaretRightIcon: React.FunctionComponent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="14"
      viewBox="0 0 11 14"
      fill="none"
    >
      <path
        d="M1 1L9.42919 5.68933C10.1143 6.07048 10.1143 7.05593 9.42919 7.43708L1 12.1264"
        stroke="#865CD0"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default CaretRightIcon;
