import styled, { keyframes } from "styled-components";

const size = "26px";

const rotation = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const StyledCrossProgressIndicator = styled.span`
  width: ${size};
  height: ${size};
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid ${({ theme }) => theme.colors.black};
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: ${rotation} 1s linear infinite;

  &::after {
    display: block;
    content: "";
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: ${size};
    height: ${size};
    border-radius: 50%;
    //border-left: 4px solid ${({ theme }) => theme.colors.hold};
    border-left: 4px solid blue;
    opacity: 0.5;
    border-bottom: 4px solid transparent;
    animation: ${rotation} 0.5s linear infinite reverse;
  }
`;

export { StyledCrossProgressIndicator };
