import APIClient from "@/services/APIClient";
import PaymentProviderConfig from "@/apiModels/PaymentModels/PaymentProviderConfig.ts";
import { useQuery } from "@tanstack/react-query";
import BaseServices from "@/services/BaseServices";

interface Props {
  businessExternalId?: string;
}

const usePaymentProviderConfig = ({ businessExternalId }: Props) => {
  const apiClient = new APIClient<PaymentProviderConfig>(
    BaseServices.PAYMENT_SERVICE,
    `/business/${businessExternalId}/list-provider-config`
  );

  return useQuery<PaymentProviderConfig[], Error>({
    queryKey: ["paymentProviderConfig", businessExternalId],
    queryFn: () => apiClient.getList(),
    enabled: !!businessExternalId
  });
};

export default usePaymentProviderConfig;
