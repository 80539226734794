import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "styled-components";
import theme from "@/data/theme";
import { Outlet } from "react-router-dom";
import GlobalStyles from "@/data/theme/GlobalStyle.ts";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ToastContainer } from "react-toastify";
import NavBar from "@/components/ui/NavBar";
import {
  StyledBody,
  StyledHeader,
  StyledRootLayout
} from "@/components/layouts/RootLayout/RootLayout.styled.ts";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const queryClient = new QueryClient();

const RootLayout: React.FunctionComponent = () => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <QueryClientProvider client={queryClient}>
          <ToastContainer
            autoClose={2000}
            hideProgressBar={true}
            closeOnClick
            closeButton={false}
            draggable
            pauseOnHover={false}
            pauseOnFocusLoss={false}
            position={"top-center"}
          />
          <StyledRootLayout>
            <StyledHeader>
              <NavBar />
            </StyledHeader>
            <StyledBody>
              <Outlet />
            </StyledBody>
          </StyledRootLayout>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </ThemeProvider>
    </>
  );
};

export default RootLayout;
