import axios from "axios";

const axiosInstance = axios.create({
  baseURL: `${import.meta.env.VITE_API_URL}/v${import.meta.env.VITE_API_VERSION}`,
  timeout: 1000 * 10 // 10 seconds
});

class APIClient<T> {
  endpoint: string;
  pagination: boolean;

  constructor(
    baseService: string,
    endpoint: string,
    pagination: boolean = false
  ) {
    this.endpoint = `${baseService}/v${import.meta.env.VITE_API_VERSION}${endpoint}`;
    this.pagination = pagination;
  }

  get = async (params?: Record<string, unknown>): Promise<T> => {
    const res = await axiosInstance.get<T>(this.endpoint, {
      params: {
        ...(params ? params : {})
      }
    });
    return res.data;
  };

  getList = async (params?: Record<string, unknown>): Promise<T[]> => {
    const res = await axiosInstance.get<T[]>(this.endpoint, {
      params: {
        ...(this.pagination ? { pagination: "false" } : {}),
        ...(params ? params : {})
      }
    });
    return res.data;
  };

  getFile = () => {
    return axiosInstance
      .get<T>(this.endpoint, {
        responseType: "blob"
      })
      .then((res) => res.data);
  };

  post = <P>(data: T) => {
    return axiosInstance.post<P>(this.endpoint, data).then((res) => res.data);
  };
}

export default APIClient;
