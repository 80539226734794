import styled from "styled-components";

type Props = {
  variant: "NORMAL" | "DASHED";
  stroke: `${number}px` | `${number}rem` | `${number}em`;
};

const StyledLine = styled.hr<Props>`
  border-style: none;
  border-top: ${({ stroke }) => stroke}
    ${({ variant }) => (variant === "NORMAL" ? "solid" : "dashed")}
    ${({ theme }) => theme.colors.primary};
`;

export default StyledLine;
