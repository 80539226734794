import React from "react";
import {
  StyledBusinessBrandAddress,
  StyledBusinessBrandBar,
  StyledBusinessBrandEventName,
  StyledBusinessBrandInfo,
  StyledBusinessBrandLogo,
  StyledBusinessBrandLogoWrapper,
  StyledBusinessBrandName
  // StyledBusinessBrandSocialLinkIcon,
  // StyledBusinessBrandSocialLinkItem,
  // StyledBusinessBrandSocialLinks
} from "@/components/ui/BusinessBrandBar/BusinessBrandBar.styled.ts";
import { VenueSocial } from "@/apiModels/ReservationScheduleAPIModel";

type Props = {
  eventName: string;
  brandIcon: string;
  brandName: string;
  brandAddress: string;
  brandSocialLinks: VenueSocial[];
};

const BusinessBrandBar: React.FunctionComponent<Props> = ({
  eventName,
  brandIcon,
  brandName,
  brandAddress
  // brandSocialLinks
}) => {
  return (
    <StyledBusinessBrandBar>
      <StyledBusinessBrandLogoWrapper>
        <StyledBusinessBrandLogo src={brandIcon} alt={brandName} />
      </StyledBusinessBrandLogoWrapper>
      <StyledBusinessBrandInfo>
        <StyledBusinessBrandEventName>{eventName}</StyledBusinessBrandEventName>
        <StyledBusinessBrandName>{brandName}</StyledBusinessBrandName>
        <StyledBusinessBrandAddress>{brandAddress}</StyledBusinessBrandAddress>
        {/*<StyledBusinessBrandSocialLinks>*/}
        {/*  {brandSocialLinks.map((social, index) => (*/}
        {/*    <StyledBusinessBrandSocialLinkItem*/}
        {/*      key={index}*/}
        {/*      href={social.link}*/}
        {/*      target={"_blank"}*/}
        {/*    >*/}
        {/*      <StyledBusinessBrandSocialLinkIcon*/}
        {/*        src={social.svg}*/}
        {/*        alt={social.name}*/}
        {/*      />*/}
        {/*    </StyledBusinessBrandSocialLinkItem>*/}
        {/*  ))}*/}
        {/*</StyledBusinessBrandSocialLinks>*/}
      </StyledBusinessBrandInfo>
    </StyledBusinessBrandBar>
  );
};

export default BusinessBrandBar;
