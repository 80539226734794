import styled from "styled-components";

const StyledInfoModalHead = styled.h2`
  color: ${({ theme }) => theme.colors.black};
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const StyledInfoModalDescription = styled.span`
  color: ${({ theme }) => theme.colors.inputPlaceholderColor};
  text-align: justify;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 12px 0;
`;

const StyledInfoModalTableDetailWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;

  &:not(:last-child) {
    margin-bottom: 5px;
  }
`;

const StyledInfoModalTableDetail = styled.div``;

const StyledInfoModalTableDetailLabel = styled.span`
  color: ${({ theme }) => theme.colors.inputPlaceholderColor};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const StyledInfoModalTableDetailValue = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const StyledInfoModalActionButton = styled.button`
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  width: 100%;
  padding: 10px 0;
  border-radius: 5px;
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 12px;
`;

const StyledInfoModalActionWrapper = styled.div``;

export {
  StyledInfoModalHead,
  StyledInfoModalDescription,
  StyledInfoModalTableDetailWrapper,
  StyledInfoModalTableDetail,
  StyledInfoModalTableDetailLabel,
  StyledInfoModalTableDetailValue,
  StyledInfoModalActionWrapper,
  StyledInfoModalActionButton
};
