import React from "react";
import Card from "@/components/ui/Card";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import {
  Note,
  PersonalDetailFieldWrapper,
  PersonalDetailInputField,
  PersonalDetailLabel,
  PersonalDetailPhoneInputWrapper,
  PersonalDetailRequiredIndicator,
  StyledInputFieldError
} from "@/components/ui/PersonalDetailEntry/PersonalDetailEntry.styled.ts";
import { E164Number } from "libphonenumber-js";

type Props = {
  fullName: string;
  setFullName: (fullName: string) => void;
  email: string;
  setEmail: (email: string) => void;
  phone_number: string | undefined;
  setPhone: (phone: E164Number) => void;
  emptyFullNameError: string;
  emptyEmailError: string;
  emptyPhoneNumberError: string;
  invalidEmailTypeError: string;
};

const PersonalDetailEntry: React.FunctionComponent<Props> = ({
  fullName,
  setFullName,
  phone_number,
  setPhone,
  setEmail,
  email,
  emptyFullNameError,
  emptyEmailError,
  emptyPhoneNumberError,
  invalidEmailTypeError
}) => {
  return (
    <Card
      accent={true}
      margin={{
        top: "1.25rem",
        bottom: "1.25rem"
      }}
      padding={{
        top: "0.625rem",
        left: "0.625rem",
        bottom: "0.625rem",
        right: "0.625rem"
      }}
    >
      <PersonalDetailFieldWrapper>
        <PersonalDetailLabel>
          Full Name
          <PersonalDetailRequiredIndicator>*</PersonalDetailRequiredIndicator>
        </PersonalDetailLabel>
        <PersonalDetailInputField
          type={"text"}
          placeholder={"Enter Full Name"}
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}
          $emptyError={emptyFullNameError}
        />
        {emptyFullNameError && (
          <StyledInputFieldError>{emptyFullNameError}</StyledInputFieldError>
        )}
      </PersonalDetailFieldWrapper>

      <PersonalDetailFieldWrapper>
        <PersonalDetailLabel>
          Mobile Number
          <PersonalDetailRequiredIndicator>*</PersonalDetailRequiredIndicator>
        </PersonalDetailLabel>
        <PersonalDetailPhoneInputWrapper $emptyError={emptyPhoneNumberError}>
          <PhoneInput
            placeholder="Enter Mobile Number"
            value={phone_number}
            onChange={setPhone}
            defaultCountry={"NP"}
          />
        </PersonalDetailPhoneInputWrapper>
        {emptyPhoneNumberError && (
          <StyledInputFieldError>{emptyPhoneNumberError}</StyledInputFieldError>
        )}
      </PersonalDetailFieldWrapper>

      <PersonalDetailFieldWrapper>
        <PersonalDetailLabel>
          Email Address
          <PersonalDetailRequiredIndicator>*</PersonalDetailRequiredIndicator>
        </PersonalDetailLabel>
        <PersonalDetailInputField
          type={"email"}
          placeholder={"Enter Email Address"}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          $emptyError={emptyEmailError || invalidEmailTypeError}
        />
        {emptyEmailError && (
          <StyledInputFieldError>{emptyEmailError}</StyledInputFieldError>
        )}
        {!emptyEmailError && invalidEmailTypeError && (
          <StyledInputFieldError>{invalidEmailTypeError}</StyledInputFieldError>
        )}
      </PersonalDetailFieldWrapper>

      {/*    Note   */}
      <Note>[Note:- Your reservations will be sent in this email id.]</Note>
    </Card>
  );
};

export default PersonalDetailEntry;
