import APIClient from "@/services/APIClient";
import { useQuery } from "@tanstack/react-query";
import ReservationScheduleAPIModel from "@/apiModels/ReservationScheduleAPIModel";
import BaseServices from "@/services/BaseServices";

interface Props {
  scheduleQrCode?: string;
}

const useReservationSchedule = ({ scheduleQrCode }: Props) => {
  const apiClient = new APIClient<ReservationScheduleAPIModel>(
    BaseServices.SEAT_RESERVATION_SERVICE,
    `/reservation/schedule/${scheduleQrCode}/reservation-schedule-detail`
  );

  return useQuery<ReservationScheduleAPIModel, Error>({
    queryKey: ["reservationSchedule", scheduleQrCode],
    queryFn: () => apiClient.get(),
    enabled: !!scheduleQrCode,
    retry: false
  });
};

export default useReservationSchedule;
