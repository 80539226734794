import { PropsWithChildren } from "react";
import StyledCard from "@/components/ui/Card/Card.styled.ts";

type Props = {
  accent: boolean;
  borderRadius?: string;
  margin?: Margin;
  padding?: Padding;
} & PropsWithChildren;

const Card: React.FunctionComponent<Props> = ({
  children,
  borderRadius = "10px",
  accent,
  padding = { top: "0", bottom: "0", left: "0", right: "0" },
  margin = { top: "0", bottom: "0", left: "0", right: "0" }
}) => {
  padding = {
    ...padding,
    top: padding.top || "0",
    bottom: padding.bottom || "0",
    left: padding.left || "0",
    right: padding.right || "0"
  };

  margin = {
    ...margin,
    top: margin.top || "0",
    bottom: margin.bottom || "0",
    left: margin.left || "0",
    right: margin.right || "0"
  };

  return (
    <StyledCard
      $accent={accent}
      $borderRadius={borderRadius}
      $margin={margin}
      $padding={padding}
    >
      {children}
    </StyledCard>
  );
};

export default Card;
