import React from "react";
import {
  StyledDeleteResActionWrapper,
  StyledDeleteResDescription,
  StyledDeleteResHead,
  StyledFilledConfirmButton,
  StyledOutlineCancelButton
} from "@/components/ui/Modals/DeleteReservationModal/DeleteReservationModal.styled.ts";
import Modal from "react-modal";
import TableAPIModel from "@/apiModels/TableAPIModel";
import theme from "@/data/theme";

interface IDeleteReservationModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  reservation: TableAPIModel;
}

const DeleteReservationModal: React.FunctionComponent<
  IDeleteReservationModalProps
> = ({ isOpen, onCancel, onConfirm, reservation }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCancel}
      style={{
        content: {
          maxWidth: "320px",
          minWidth: "296px",
          height: "fit-content",
          borderRadius: "10px",
          padding: "25px 10px",
          inset: "0",
          margin: "auto",
          boxShadow: "0px 2px 6px 0px rgba(134, 92, 208, 0.50)"
        },
        overlay: {
          background: theme.colors.modalBackground
        }
      }}
    >
      <StyledDeleteResHead>Are you sure?</StyledDeleteResHead>
      <StyledDeleteResDescription>
        Do you really want to delete this reservation
        <span>&nbsp;{reservation.serial_no}</span>. This section cannot be
        undone.
      </StyledDeleteResDescription>
      <StyledDeleteResActionWrapper>
        <StyledOutlineCancelButton onClick={onCancel}>
          Cancel
        </StyledOutlineCancelButton>
        <StyledFilledConfirmButton onClick={onConfirm}>
          Confirm
        </StyledFilledConfirmButton>
      </StyledDeleteResActionWrapper>
    </Modal>
  );
};

export default DeleteReservationModal;
