import React from "react";
import { StyledSectionTitle } from "@/components/ui/SectionTitle/SectionTitle.styled.ts";

interface SectionTitleProps {
  children: React.ReactNode;
}

const SectionTitle: React.FunctionComponent<SectionTitleProps> = ({
  children
}) => {
  return <StyledSectionTitle>{children}</StyledSectionTitle>;
};
export default SectionTitle;
