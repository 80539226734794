import React from "react";

const PlayStoreIcon: React.FunctionComponent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="27"
      viewBox="0 0 24 27"
      fill="none"
    >
      <path
        d="M0.452582 1.52142C0.165906 1.81402 0 2.26955 0 2.8595V23.9024C0 24.4924 0.165906 24.9479 0.452582 25.2405L0.523337 25.3047L12.6174 13.5178V13.2394L0.523337 1.45244L0.452582 1.52142Z"
        fill="#865CD0"
      />
      <path
        d="M18.108 17.4487L14.0811 13.5177V13.2394L18.1128 9.30839L18.2031 9.35953L22.9778 12.0083C24.3404 12.76 24.3404 13.997 22.9778 14.7535L18.2031 17.3975L18.108 17.4487Z"
        fill="#865CD0"
      />
      <path
        d="M17.4715 18.1295L13.3494 14.1105L1.18457 25.9724C1.63715 26.4363 2.37519 26.4922 3.21449 26.0283L17.4715 18.1295Z"
        fill="#865CD0"
      />
      <path
        d="M17.4715 8.62761L3.21449 0.72878C2.37519 0.26967 1.63715 0.32557 1.18457 0.789438L13.3494 12.6466L17.4715 8.62761Z"
        fill="#865CD0"
      />
    </svg>
  );
};

export default PlayStoreIcon;
