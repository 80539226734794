import styled, { keyframes } from "styled-components";

const qrDiagonalSkew = "skew(-10deg, -14deg)";

const qrScanDiagonal = keyframes`
  0% {
    transform: translate(0, 0) ${qrDiagonalSkew};
  }
  100% {
    transform: translate(70px, 70px) ${qrDiagonalSkew};
  }
`;

const StyledAnimatedInvalidQR = styled.div`
  position: relative;
  height: 244px;
  width: 318px;
  margin-bottom: 1rem;
`;

const ScanningPhone = styled.div`
  z-index: 3;
  position: absolute;

  //img {
  //  box-shadow: -2px 60px 15px 1px rgba(134, 92, 208, 0.68);
  //  -webkit-box-shadow: -2px 60px 15px 1px rgba(134, 92, 208, 0.68);
  //  -moz-box-shadow: -2px 60px 15px 1px rgba(134, 92, 208, 0.68);
  //}
`;

const QRRay = styled.div`
  z-index: 2;
  position: absolute;
  background: rgba(244, 52, 52, 0.3);
  height: 95px;
  width: 95px;
  border-bottom: 3px solid rgba(244, 52, 52);
  transform: translate(35px, 35px) ${qrDiagonalSkew};
  top: 48px;
  left: 32px;
  animation: ${qrScanDiagonal} 2s linear 2.5 alternate;
`;

const BaseQR = styled.div`
  z-index: 0;
  position: absolute;
  inset: 0;
  left: 10px;
  transform: translateY(45%);
`;

const ErrorContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;

  & > * {
    margin-top: 0.5rem;
  }

  h1 {
    color: ${({ theme }) => theme.colors.danger};
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
  }

  p {
    color: ${({ theme }) => theme.colors.inputPlaceholderColor};
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
  }
`;

export { StyledAnimatedInvalidQR, ScanningPhone, QRRay, BaseQR, ErrorContent };
