import React from "react";

const CalendarIcon: React.FunctionComponent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
    >
      <path
        d="M13.5 2.74336H17.1C17.5971 2.74336 18 3.14631 18 3.64336V18.0434C18 18.5404 17.5971 18.9434 17.1 18.9434H0.9C0.402948 18.9434 0 18.5404 0 18.0434V3.64336C0 3.14631 0.402948 2.74336 0.9 2.74336H4.5V0.943359H6.3V2.74336H11.7V0.943359H13.5V2.74336ZM1.8 8.14336V17.1434H16.2V8.14336H1.8ZM3.6 11.7434H8.1V15.3434H3.6V11.7434Z"
        fill="#865CD0"
      />
    </svg>
  );
};

export default CalendarIcon;
