import React, { PropsWithChildren } from "react";
import StyledContainer from "@/components/ui/Container/Container.styled.ts";

export const Container: React.FunctionComponent<PropsWithChildren> = ({
  children
}) => {
  return <StyledContainer>{children}</StyledContainer>;
};

export default Container;
