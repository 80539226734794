import styled from "styled-components";

interface BottomBarProps {
  $isSeatSelected: boolean;
}

interface BottomBarActionButtonProps {
  $isLoading: boolean;
}

const StyledBottomBar = styled.div`
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 5px 20px -5px ${({ theme }) => theme.colors.bottomBarShadowColor};
  width: 100%;
  position: sticky;
  bottom: 0;
`;

const StyledBottomBarActionButton = styled.button<BottomBarActionButtonProps>`
  border: inherit;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.fonts.fontSizeMblHeadingTwo};
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 8px;
  padding: 0.75rem 1.25rem;
  background: ${({ theme }) => theme.colors.primaryLight};
  box-shadow: ${({ theme }) => theme.shadows.buttonShadow};

  ${(props) =>
    (props.$isLoading || props.disabled) &&
    `
        pointer-events: none;
        opacity: 0.3;
  `}
`;

const StyledBottomBarContent = styled.div<BottomBarProps>`
  padding: 0.5rem 1.25rem;
  display: flex;
  justify-content: ${(props) =>
    props.$isSeatSelected ? "space-between" : "center"};
  align-content: center;

  ${StyledBottomBarActionButton} {
    width: ${(props) => (props.$isSeatSelected ? "fit-content" : "100%")};
  }
`;

const StyledBottomBarPriceWrapper = styled.div``;

const StyledBottomBarPrice = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.fonts.fontSizeMblHeadingTwo};
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const StyledBottomBarTotalTicket = styled.div`
  font-size: ${({ theme }) => theme.fonts.fontSizeMblNormal};
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${({ theme }) => theme.colors.darkSlateColor};

  span:last-child {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export {
  StyledBottomBar,
  StyledBottomBarContent,
  StyledBottomBarPriceWrapper,
  StyledBottomBarPrice,
  StyledBottomBarTotalTicket,
  StyledBottomBarActionButton
};
