import React from "react";

const CaretLeftIcon: React.FunctionComponent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="14"
      viewBox="0 0 11 14"
      fill="none"
    >
      <path
        d="M10 12.1265L1.57081 7.43714C0.885682 7.05598 0.885681 6.07054 1.57081 5.68939L10 1.00006"
        stroke="#865CD0"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default CaretLeftIcon;
