import React from "react";
import Container from "@/components/ui/Container";
import {
  BaseQR,
  ErrorContent,
  QRRay,
  ScanningPhone,
  StyledAnimatedInvalidQR
} from "@/components/errors/NotFoundErrorPage.styled.ts";
import ErrorQRImage from "@/components/ui/ErrorQRImage";
import revelPhoneScanner from "@/assets/images/ErrorRevelPhoneScanner.png";

const NotFoundErrorPage: React.FunctionComponent = () => {
  return (
    <Container>
      <StyledAnimatedInvalidQR>
        <ScanningPhone>
          <img src={revelPhoneScanner} alt={"Phone"} />
        </ScanningPhone>
        <QRRay></QRRay>
        <BaseQR>
          <ErrorQRImage />
        </BaseQR>
      </StyledAnimatedInvalidQR>
      <ErrorContent>
        <h1>QR Scan Failed</h1>
        <p>The QR you scanned is not supported. Please try again!!</p>
      </ErrorContent>
      {/*<MoreInquiryCard/>*/}
    </Container>
  );
};

export default NotFoundErrorPage;
