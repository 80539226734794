const theme = {
  colors: {
    white: "#FFFFFF",
    black: "#000000",
    primary: "#865CD0",
    primaryLight: "rgba(134, 92, 208, 0.30)",
    primaryXLight: "rgba(134, 92, 208, 0.20)",
    primaryXXLight: "rgba(134, 92, 208, 0.10)",
    danger: "#F43434",
    dangerLight: "rgba(244, 52, 52, 0.1)",
    available: "#362315",
    hold: "#F49425",
    booked: "#DF0A0C",
    modalBackground: "rgba(47, 47, 47, 0.85)",
    badgeBgGreyColor: "#6B6D81",
    lightGreyColor: "rgba(0, 0, 0, 0.7)",
    inputPlaceholderColor: "rgba(0, 0, 0, 0.5)",
    bottomBarShadowColor: "rgba(0, 0, 0, 0.40)",
    venueTileShadowColor: "rgba(0, 0, 0, 0.10)",
    lightestGreyColor: "rgba(12, 12, 13, 0.45)",
    darkSlateColor: "#0C0C0D",
    inputBorderColor: "#D9D9D9",
    radioButtonBorderColor: "#BCB8B8"
  },
  shadows: {
    buttonShadow: "0px 2px 6px 0px rgba(134, 92, 208, 0.20)",
    cardBoxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.25)",
    cardBoxAccentShadow: "0px 2px 6px 0px rgba(134, 92, 208, 0.50)",
    inputFieldShadow: "0 2px 6px 0 rgba(0, 0, 0, 0.08)"
  },
  fonts: {
    fontSize5xl: "40px",
    fontSize4xl: "32px",
    fontSizeMblHeadingMain: "20px",
    fontSizeMblHeadingOne: "18px",
    fontSizeMblHeadingTwo: "16px",
    fontSizeMblHeadingThree: "15px",
    fontSizeLg: "14px",
    fontSizeMblHeadingFour: "13px",
    fontSizeMblNormal: "13px",
    fontSizeSm: "12px",
    fontSizeMblNormalOne: "11px",
    fontSizeBase: "11px"
  },
  lineHeights: {
    lineHeightNormal: "normal",
    lineHeightMd: "22px",
    lineHeightSm: "20px"
  },
  letterSpacings: {
    letterSpacingSm: "-0.28px",
    letterSpacingLg: "0.4px",
    letterSpacingXSm: "-0.41px"
  }
} as const;

export default theme;
