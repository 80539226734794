import styled from "styled-components";

const paddingX = "15px";

interface DownloadButtonProp {
  $isLoading: boolean;
}

const StyledPaySuccessTicketCard = styled.div`
  inset: 0;
  margin: auto;
  position: relative;
  height: fit-content;
  width: fit-content;
`;

const TicketShape = styled.img``;

const SuccessTicketContentOverlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  inset: 0;
  padding: 12px ${paddingX} 24px;
`;

const SuccessTicketContent = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const TicketSuccessCompartment = styled.div`
  position: absolute;
  top: 0;
  height: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SuccessTicketHead = styled.h2`
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 0.5rem;
`;

const SuccessTicketDescription = styled.p`
  color: rgba(46, 46, 46, 0.5);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0.5rem 0;
`;

const SuccessTicketQRImage = styled.img`
  margin: 0.5rem 0;
`;

const SuccessTicketQRCode = styled.span``;

const TicketDetailCompartment = styled.div`
  position: absolute;
  top: 50%;
  width: 100%;
  height: 50%;

  & > * {
    margin: 0.25rem 0;
  }
`;

const TicketName = styled.h3`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const TickerBuyerName = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.black};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const TicketPurchaseDate = styled(TickerBuyerName)``;

const TicketEventVenue = styled.span`
  color: rgba(12, 12, 13, 0.7);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 0;
`;

const TicketInvoiceListTile = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const TicketInvoiceListTileLeading = styled.span``;

const TicketInvoiceListTileTrailing = styled.span``;

const TicketInvoiceList = styled.ul`
  color: rgba(0, 0, 0, 0.7);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  & > li {
    margin: 0.25rem 0;
  }
`;

const TicketInvoiceConclusionTile = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const TicketInvoiceConclusionTileLeading = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const TicketInvoiceConclusionTileTrailing = styled(
  TicketInvoiceConclusionTileLeading
)``;

const DownloadTicketButton = styled.button<DownloadButtonProp>`
  position: absolute;
  bottom: 2%;
  background-color: white;
  display: flex;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0.5rem 0;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  gap: 10px;
  cursor: pointer;

  ${(props) =>
    props.$isLoading &&
    `
    opacity: 0.1;
    pointer-events: none;
  `}
`;

export {
  StyledPaySuccessTicketCard,
  TicketShape,
  SuccessTicketContentOverlay,
  SuccessTicketContent,
  TicketSuccessCompartment,
  SuccessTicketHead,
  SuccessTicketDescription,
  SuccessTicketQRImage,
  SuccessTicketQRCode,
  TicketDetailCompartment,
  TicketName,
  TickerBuyerName,
  TicketPurchaseDate,
  TicketEventVenue,
  TicketInvoiceList,
  TicketInvoiceListTile,
  TicketInvoiceListTileLeading,
  TicketInvoiceListTileTrailing,
  TicketInvoiceConclusionTile,
  TicketInvoiceConclusionTileLeading,
  TicketInvoiceConclusionTileTrailing,
  DownloadTicketButton
};
