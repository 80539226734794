import React, { useEffect, useRef, useState } from "react";
import {
  ReactZoomPanPinchRef,
  TransformComponent,
  TransformWrapper
} from "react-zoom-pan-pinch";
import SVGMapComponent from "@/components/ui/SeatSelector/_components/SVGMapComponent";
import SeatSelectorControl from "@/components/ui/SeatSelector/_components/SeatSelectorControl";
import {
  StyledLegend,
  StyledLegendWrapper
} from "@/views/QRView/QRSeatBookingPage/QRSeatBookingPage.styled.ts";
import { StyledSeatOptionWrapper } from "@/components/ui/SeatSelector/SeatSelector.styled.ts";
import { StyledInputFieldError } from "@/components/ui/PersonalDetailEntry/PersonalDetailEntry.styled.ts";
import ConfirmReservationModal from "@/components/ui/Modals/ConfirmReservationModal";
import TableAPIModel from "@/apiModels/TableAPIModel";
import updateSeatColor from "@/utils/updateSeatColor.ts";
import theme from "@/data/theme";
import useBulkTableCartStore from "@/stores/BulkTableCartStore.ts";
import { MapArea } from "@/data/venueMapData";
import { useLocation } from "react-router-dom";

interface Props {
  errorText: string;
  data: TableAPIModel[] | undefined;
  venueMap: MapArea;
}

const SeatSelector: React.FunctionComponent<Props> = ({
  errorText,
  data,
  venueMap
}) => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [selectedReservation, setSelectedReservation] =
    useState<TableAPIModel | null>(null);
  const location = useLocation();

  const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null);

  const setReservationTables = useBulkTableCartStore(
    (store) => store.setReservationTables
  );

  useEffect(() => {
    const switchArea = localStorage.getItem("switch");

    if (location.pathname.split("/")[2] !== switchArea) {
      localStorage.setItem("selectedReservations", JSON.stringify([]));
    }
  }, [location.pathname]);

  const onConfirm = () => {
    if (selectedReservation) {
      const resArray = JSON.parse(
        String(localStorage.getItem("selectedReservations"))
      ) as unknown as TableAPIModel[];

      resArray.push(selectedReservation);
      localStorage.setItem("selectedReservations", JSON.stringify(resArray));
      updateSeatColor({
        seat: selectedReservation.serial_no,
        color: theme.colors.primary
      });
      setReservationTables(resArray);
    }

    onCancel();
    setIsConfirmModalOpen(false);
  };

  const onCancel = () => {
    setIsConfirmModalOpen(false);
  };

  return (
    <TransformWrapper
      ref={transformComponentRef}
      initialScale={1.18}
      // initialPositionY={0}
      // initialPositionX={-45}
      smooth
      limitToBounds
      disablePadding
      centerOnInit
    >
      {(utils) => (
        <React.Fragment>
          <TransformComponent
            wrapperStyle={{
              borderRadius: "5px",
              width: "100%",
              backgroundColor: "black"
            }}
            contentStyle={{
              width: "100%",
              aspectRatio: "1/1.28",
              border: errorText ? `2px solid ${theme.colors.danger}` : "none"
            }}
          >
            {data && (
              <SVGMapComponent
                seats={data}
                modelDispatcher={setIsConfirmModalOpen}
                setSelectedReservation={setSelectedReservation}
                venueMap={venueMap}
              />
            )}
          </TransformComponent>
          {errorText && (
            <StyledInputFieldError>{errorText}</StyledInputFieldError>
          )}
          <StyledSeatOptionWrapper>
            <StyledLegendWrapper>
              <StyledLegend $tableStatus={"available"}>Available</StyledLegend>
              <StyledLegend $tableStatus={"hold"}>Hold</StyledLegend>
              <StyledLegend $tableStatus={"booked"}>Booked</StyledLegend>
            </StyledLegendWrapper>
            <SeatSelectorControl {...utils} />
          </StyledSeatOptionWrapper>
          {data && (
            <ConfirmReservationModal
              onConfirm={onConfirm}
              isOpen={isConfirmModalOpen}
              onCancel={onCancel}
              selected={selectedReservation || ({} as TableAPIModel)}
            />
          )}
        </React.Fragment>
      )}
    </TransformWrapper>
  );
};

export default SeatSelector;
