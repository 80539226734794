import React from "react";
import Modal from "react-modal";
import {
  StyledInfoModalDescription,
  StyledInfoModalHead,
  StyledInfoModalTableDetail,
  StyledInfoModalTableDetailLabel,
  StyledInfoModalTableDetailValue,
  StyledInfoModalTableDetailWrapper
} from "@/components/ui/Modals/ReservationInfoModal/ReservationInfoModal.styled.ts";
import { StyledDeleteResActionWrapper } from "@/components/ui/Modals/DeleteReservationModal/DeleteReservationModal.styled.ts";
import {
  StyledConfirmReservationFilledAction,
  StyledConfirmReservationOutlineAction
} from "@/components/ui/Modals/ConfirmReservationModal/ConfirmReservationModal.styled.ts";
import TableAPIModel from "@/apiModels/TableAPIModel";
import theme from "@/data/theme";
import parse from "html-react-parser";

interface IConfirmReservationModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  selected: TableAPIModel;
}

const ConfirmReservationModal: React.FunctionComponent<
  IConfirmReservationModalProps
> = ({ isOpen, onConfirm, onCancel, selected }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCancel}
      style={{
        content: {
          maxWidth: "320px",
          minWidth: "296px",
          height: "fit-content",
          borderRadius: "10px",
          padding: "25px 10px",
          inset: "0",
          margin: "auto",
          boxShadow: "0px 2px 6px 0px rgba(134, 92, 208, 0.50)"
        },
        overlay: {
          background: theme.colors.modalBackground
        }
      }}
    >
      <StyledInfoModalHead>Confirm Your Reservation</StyledInfoModalHead>
      <StyledInfoModalDescription>
        {parse(String(selected.features))}
      </StyledInfoModalDescription>
      <StyledInfoModalTableDetailWrapper>
        <StyledInfoModalTableDetail>
          <StyledInfoModalTableDetailLabel>
            Table:&nbsp;
          </StyledInfoModalTableDetailLabel>
          <StyledInfoModalTableDetailValue>
            {selected.serial_no}
          </StyledInfoModalTableDetailValue>
        </StyledInfoModalTableDetail>

        <StyledInfoModalTableDetail>
          <StyledInfoModalTableDetailLabel>
            Pax:&nbsp;
          </StyledInfoModalTableDetailLabel>
          <StyledInfoModalTableDetailValue>
            {selected.pax}
          </StyledInfoModalTableDetailValue>
        </StyledInfoModalTableDetail>
      </StyledInfoModalTableDetailWrapper>
      <StyledInfoModalTableDetailWrapper>
        <StyledInfoModalTableDetail>
          <StyledInfoModalTableDetailLabel>
            Total Amount:&nbsp;
          </StyledInfoModalTableDetailLabel>
          <StyledInfoModalTableDetailValue>
            Rs. {Number(selected.price).toLocaleString("en-In")}
          </StyledInfoModalTableDetailValue>
        </StyledInfoModalTableDetail>
      </StyledInfoModalTableDetailWrapper>
      <StyledDeleteResActionWrapper>
        <StyledConfirmReservationOutlineAction onClick={onCancel}>
          Cancel
        </StyledConfirmReservationOutlineAction>
        <StyledConfirmReservationFilledAction onClick={onConfirm}>
          Confirm
        </StyledConfirmReservationFilledAction>
      </StyledDeleteResActionWrapper>
    </Modal>
  );
};

export default ConfirmReservationModal;
